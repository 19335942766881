import React, { useState, useEffect } from 'react';
import AdvertiserAdditionalContactModal from './AdvertiserAdditionalContactModal';
import { useAuth, useProtectedContent } from '../../context/AuthContext';
import { User } from 'react-feather'

export default function AdvertiserAdditionalContacts({advertiserId, additionalContacts, refetch}){

    const auth = useAuth()
    let showAdvertiserSettings = useProtectedContent(auth.permissions, 'advertiser_settings')

    const [additionalContactModalData, setAdditionalContactModalData] = useState({open:false, name: '', e_mail: '', notes: ''})

    return (
        <>
            <AdvertiserAdditionalContactModal advertiserId={advertiserId} modalData={additionalContactModalData} setModalData={setAdditionalContactModalData} refetch={refetch} />
            <div className="border-bottom">
                <table className="table table-card mb-0">                    
                    <tr>
                        <th className="align-middle">Additional Contacts</th>
                        <th className="align-middle">Email</th>
                        <th className="align-middle">Notes</th>
                        <th className='text-right'>{showAdvertiserSettings && <button className="btn btn-warning btn-sm" onClick={(e)=>{e.preventDefault(); setAdditionalContactModalData({...additionalContactModalData, open: true})}}>+ Add contact</button>}</th>
                    </tr>
                    <tbody>
                        {
                            additionalContacts && additionalContacts.length > 0 ?
                                additionalContacts.map(additionalContact => {
                                    return (
                                        <tr key={additionalContact.id}>
                                            <td><div className="px-1 align-items-center d-flex"> <User size={13} fill="black" /> <span className="ml-1">{additionalContact.name}</span></div></td>
                                            <td><a href={`mailto:${additionalContact.e_mail}`}>{additionalContact.e_mail}</a></td>
                                            <td>{additionalContact.notes}</td>
                                            <td className="text-right">{showAdvertiserSettings &&  <button className="btn btn-warning btn-sm" onClick={(e)=>{e.preventDefault(); setAdditionalContactModalData({open: true, id:additionalContact.id, name: additionalContact.name, e_mail: additionalContact.e_mail, notes: additionalContact.notes})}}>Edit</button>}</td>
                                        </tr>
                                    )
                                })
                            :
                                <tr>
                                    <td colSpan={4}>No additional contacts.</td>
                                </tr>
                        }                        
                    </tbody>
                </table>
            </div>
        </>
    )
}