import React, { useState, useEffect } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import useCallApi from '../../hooks/useCallApi'
import Loading from '../../templates/UI/Loading/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function AffiliateSearch({ affiliate, setAffiliate, setFormData, order }) {
    /*
        Props:
        1. affiliate OBJECT {id: 0, name: ''}
        2. setAffiliate FUNCTION - Sets an affiliate object {id: 0, name: ''} in the parent component
        3. setFormData FUNCTION  - Modifies an object from the parent component by adding an affiliate object to it {...object, affiliate: {id:0,  name:''} }
        4. order is used if there are 2 instances of the affiliate search one atop of the other. It is for setting z-indexes. Higher order = higher z value. Use 1, 2, 3...
    */

    // Get the affiliates
    let apiUrl = `affiliates/search/mini`

    const { dataLoaded, data, refetch, setData } = useCallApi(apiUrl, 'GET', {}, [])
    const [showInput, setShowInput] = useState(affiliate ? false : true)

    useEffect(() => {
        // If the affiliate object toggles between null and an object after the component mounts, show or hide the input
        if (affiliate) {
            setShowInput(false)
        } else {
            setShowInput(true)
        }
    }, [affiliate])

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
    }

    const handleOnHover = (result) => {
        // the item hovered
    }

    const handleOnSelect = (item) => {
        // This can be used to directly modify an affiliate object from the parent
        if (setAffiliate) {
            setAffiliate(item)
        }

        // This can be used if the campaign data needs to be ADDED to a data object from the parent
        if (setFormData) {
            setFormData((prevData) => ({ ...prevData, affiliate: item }))
        }
    }

    const handleOnFocus = () => {}

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>
                    {item.name}
                </span>
            </>
        )
    }

    const getProcessedData = () => {
        let newArray = []
        if (dataLoaded){
            data.search_results.forEach( item => {
                newArray.push({
                    id: item.id,
                    name: `${item.name} (${item.id})`
                })
            })
        }        
        return newArray
    }

    return (
        <>
            {showInput ? (
                dataLoaded ? (
                    <ReactSearchAutocomplete
                        items={getProcessedData()}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        autoFocus
                        formatResult={formatResult}
                        maxResults={100}
                        fuseOptions={{ keys: ['name', 'id'] }}
                        styling={{
                            height: '27px',
                            borderRadius: '3px',
                            boxShadow: 'none',
                            border: '1px solid #ced4da',
                            fontSize: '1rem',
                            zIndex: order ? order * 99 : 99,
                        }}
                    />
                ) : (
                    // Loading
                    <div
                        style={{
                            height: '29px',
                            borderRadius: '3px',
                            boxShadow: 'none',
                            border: '1px solid #ced4da',
                            padding: '3px',
                        }}
                    >
                        <FontAwesomeIcon icon="spinner" spin />
                    </div>
                )
            ) : (
                <div className="d-flex align-items-center">
                    <div>
                        {affiliate.name ? affiliate.name : affiliate.id}
                    </div>
                    <div className="pl-3">
                        <button className="btn btn-warning btn-sm" onClick={() => setShowInput(true)}>
                            Change
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
