import moment from 'moment'
import { useState } from 'react'
import { Badge } from 'react-bootstrap'
import { HelpCircle } from 'react-feather'
import { FaTimes } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

import useCallApi from '../../../../hooks/useCallApi'
import ManagerDropdown from '../../../../templates/ManagerDropdown/ManagerDropdown'
import TrackingPlatforms from '../../components/TrackingPlatforms/TrackingPlatforms'
import ResetPasswordAdvertiser from './ResetPasswordAdvertiser/ResetPasswordAdvertiser'

const AdvertiserGeneralInfo = ({ generalInfo, aliases, setAliases, setIsDisabled, managers, setManagers, setPlatformId, platformId, advertiserId, showSystemMessage }) => {

    const auth = useAuth()
    const showResetPasswordButton = useProtectedContent(auth.permissions, 'advertiser_password_reset')

    const { data: mapData, dataLoaded: mapDataLoaded, refetch } = useCallApi(`advertiser/${advertiserId}/mapaccount`, 'GET', {}, [advertiserId])

    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)

    const handleManagerChange = (mgrId, type) => {
        if (type === 'advertiser') {
            setManagers({ ...managers, advManagerId: mgrId })
        } else if (type === 'bizdev') {
            setManagers({ ...managers, bizdevManagerId: mgrId })
        }
        setIsDisabled(false)
    }

    const handlePlatformChange = (platformId) => {
        setPlatformId(platformId)
        setIsDisabled(false)
    }

    const removeKeyword = (index) => {
        let tempKeywords = aliases
        tempKeywords.splice(index, 1)
        setAliases([...tempKeywords])
        setIsDisabled(false)
    }

    const addKeyword = (e) => {
        let tempKeywords = aliases

        //when pressing enter, add keyword to state
        if (e.key === 'Enter') {
            e.preventDefault()
            if (tempKeywords.includes(e.target.value) === false) {
                tempKeywords.push(e.target.value)
                setAliases([...tempKeywords])
            }
            e.target.value = ''
            setIsDisabled(false)
        }
    }

    const inputChangeHandler = () => {
        setIsDisabled(false)
    }

    const handleClose = () => {
        setShowResetPasswordModal(false)
    }

    return (
        <div className="card">
            {showResetPasswordModal && <ResetPasswordAdvertiser advertiserId={advertiserId} advertiserName={generalInfo.name} showSystemMessage={showSystemMessage} handleClose={handleClose} refetch={refetch} status={generalInfo.status} showResetPasswordModal={showResetPasswordModal} />}

            <ReactTooltip />
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>General Information</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped m-0" width="100%">
                    <tbody>
                        <tr>
                            <td width="25%" className="border-right text-right">
                                Advertiser Name
                            </td>
                            <td className="border-right" colSpan={3}>
                                <input name="name" className="form-control" type="text" defaultValue={generalInfo.name} onChange={inputChangeHandler} required />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Status</td>
                            <td className="border-right">
                                <select name="status" className="form-control" type="text" defaultValue={generalInfo.status} onChange={inputChangeHandler} required>
                                    <option value="N">New Lead</option>
                                    <option value="D">Discussion</option>
                                    <option value="G">Negotiation</option>
                                    <option value="A">Active</option>
                                    <option value="C">Cancelled</option>
                                </select>
                            </td>
                            <td className="border-right text-right">Advertiser Manager</td>
                            <td className="border-right">
                                <ManagerDropdown mgrId={managers.advManagerId} mgrSelected={(mgrId) => handleManagerChange(mgrId, 'advertiser')} />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Last Status Change</td>
                            <td className="border-right">{moment(generalInfo.status_date).utc().format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td className="border-right text-right">Biz Dev Manager</td>
                            <td className="border-right">
                                <ManagerDropdown mgrId={managers.bizdevManagerId} mgrSelected={(mgrId) => handleManagerChange(mgrId, 'bizdev')} />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Tracking Platform</td>
                            <td className="border-right">
                                <TrackingPlatforms platformId={platformId} platformSelected={handlePlatformChange} />
                            </td>
                            <td className="border-right text-right">Supports Multiple Postback</td>
                            <td className="border-right">
                                <div className="mr-2">
                                    <select name="multiple_postback" className="form-control" defaultValue={generalInfo.supports_multiple_postback} onChange={inputChangeHandler} required>
                                        <option value=""></option>
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            {/* <td className="border-right text-right">Callrouted Advertiser</td>
                            <td className="border-right">
                                <div className="mr-2">
                                    <select name="cr_advertiser" className="form-control" defaultValue={generalInfo.cr_advertiser} onChange={inputChangeHandler}>
                                        <option value=""></option>
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                    </select>
                                </div>
                            </td> */}
                            <td className="border-right text-right">Split MBID_S1</td>
                            <td className="border-right" colSpan={3}>
                                <div className="mr-2">
                                    <select name="network_split_affsub" className="form-control w-50" defaultValue={generalInfo.network_split_affsub} onChange={inputChangeHandler}>
                                        <option value=""></option>
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                    </select>
                                    <HelpCircle className="text-primary" size={14} data-tip="For network affiliates, #MBID# will translate to #MBID_S1# unless this is set to Yes." />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Aliases</td>
                            <td colSpan={3}>
                                <div className="mr-2">
                                    {aliases.map((keyword, index) => {
                                        return (
                                            <Badge pill variant="dark" className="mx-1 my-1" style={{ fontSize: '1rem' }} key={`${index}-${keyword}`}>
                                                <div className="d-flex align-items-center">
                                                    {keyword}
                                                    <button onClick={() => removeKeyword(index)} className="btn btn-link text-white btn-sm ml-2">
                                                        <FaTimes />
                                                    </button>
                                                </div>
                                            </Badge>
                                        )
                                    })}
                                    <input type="text" className="form-control" placeholder="Add aliases. Press ENTER after typing the alias." size="75" maxLength="255" onKeyDown={(e) => addKeyword(e)} />
                                </div>
                            </td>
                        </tr>
                        {showResetPasswordButton && (mapDataLoaded && (mapData && mapData.admin.user_id > 0) && <tr>
                            <td className="border-right text-right">Reset Password</td>
                            <td className="border-right" colSpan={3}>
                                <button type="button" className="btn btn-sm btn-warning" onClick={() => setShowResetPasswordModal(true)}>
                                    Initiate Password Reset
                                </button>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AdvertiserGeneralInfo
