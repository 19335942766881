import moment from 'moment'
import { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

import callAPI from '../../../helpers/callAPI'
import getSourceQuestions from '../../../helpers/getSourceQuestions'
import getTrafficType from '../../../helpers/getTrafficTypes'
import Loading from '../../../templates/UI/Loading/Loading'
import Pill from '../../../templates/UI/Pill/Pill'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RequestDetails = ({ requestId, showSystemMessage }) => {

    const [dataLoaded, setDataLoaded] = useState(false)
    const [requestDetails, setRequestDetails] = useState({})
    const [copied, setCopied] = useState('')
    const [tempFile, setTempFile] = useState('')

    useEffect(() => {
        let url = `affiliate/campaignRequests/${requestId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setRequestDetails(result.list)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => showSystemMessage('error', 'Error while fetching request details'))
    }, [requestId])

    const getQuestions = () => {
        return getSourceQuestions(requestDetails.traffic_type)
    }

    const saveAdditionalInfo = (e) => {
        e.preventDefault()

        let url = `affiliate/campaignRequests/${requestId}/additionalInfo`

        let putBody = {
            description: e.target.description.value
        }

        callAPI(url, 'PUT', putBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(error)
                showSystemMessage('error', 'Error while saving campaign request')
            })
    }

    const uploadCreative = (e) => {
        e.preventDefault()
        const customCreative = e.target.custom_creative.files[0]
        const formData = new FormData()

        formData.append('custom_creative', customCreative)
        formData.append('affiliate_id', requestDetails.affiliate_id)
        formData.append('campaign_id', requestDetails.offer_id)

        callAPI(`affiliate/campaignRequests/${requestId}/uploadCreative`, 'POST', formData, true)
            .then(result => {
                if (result["success"] === true) {
                    showSystemMessage('success', result.message)
                } else if (result["success"] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch(error => {
                showSystemMessage('error', 'Error while uploading custom creative')
            })
    }

    const downloadImage = async (affiliateId, campaignId, url) => {

        const a = document.createElement("a")
        a.href = await fetchImage(url)
        a.download = `${affiliateId}_${campaignId}_creative.jpg`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const fetchImage = async (url) => {

        const mbToken = localStorage.getItem('adm-auth-token')

        return fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'x-app-token': mbToken,
            },
        })
            .then((res) => res.blob())
            .then((blob) => URL.createObjectURL(blob))
            .catch((err) => ({ success: false, errors: { message: err.message } }))
    }

    return (
        <div className="card mb-3">
            <div className="justify-content-between border-bottom pb-2 card-header align-items-center d-flex">
                <div className='d-flex align-items-center'>
                    <div className="d-flex mr-2">Request Details</div>
                    <div style={{ whiteSpace: 'pre-line' }}><CopyToClipboard text={dataLoaded ?
                        `${requestDetails.name}
Subid: ${requestDetails.affiliate_id}
Leads/day: ${requestDetails.estimated_leads}
Custom URL: ${requestDetails.source_answer_3}
Traffic Type: ${requestDetails.source_answer_1} - ${requestDetails.source_answer_2} - ${requestDetails.custom_creatives_flag === 'E' ? 'Existing creatives' : requestDetails.custom_creatives_flag === 'M' ? 'I will make my own' : ''}
Note: ${requestDetails.description}` : ''} onCopy={() => setCopied(true)}>
                        <button type="button" className="btn btn-sm btn-outline-primary shadow-none">
                            <FontAwesomeIcon icon="copy" />&nbsp;
                            Copy Request
                        </button>
                    </CopyToClipboard>
                    </div>
                </div>
                <div className="d-flex text-secondary "> {moment(requestDetails.request_date).format('YYYY-MM-DD')}</div>
            </div>

            <div className="card-body p-0">
                {dataLoaded ? (
                    <div>
                        <div className="d-flex p-2 border-bottom">
                            <div className="align-items-center d-flex">
                                <span className="text-secondary mr-2">{requestDetails.traffic_type === 'C' ? 'Qualified Calls/day' : 'Leads/day'}</span>
                                <span>{requestDetails.estimated_leads}</span>
                            </div>{' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="d-flex align-items-center">
                                <span className="d-flex text-secondary mr-2">Traffic Type</span>
                                <span>
                                    <Pill text={getTrafficType(requestDetails.traffic_type)} />
                                </span>
                            </div>
                        </div>
                        <table className="table table-striped table-borderless m-0">
                            <tbody style={{ lineHeight: '20px' }}>
                                <tr>
                                    <td>
                                        <span className="d-block text-secondary">{getQuestions().question1}</span>
                                        <span>{requestDetails.source_answer_1}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-block text-secondary">{getQuestions().question2}</span>
                                        <span>{requestDetails.source_answer_2}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-block text-secondary">{getQuestions().question3}</span>
                                        <span>{requestDetails.source_answer_3 ? requestDetails.source_answer_3 : 'Left blank'}</span>
                                    </td>
                                </tr>
                                <tr className="d-block">
                                    <td>
                                        <span className="d-block text-secondary">Creatives to be used:</span>
                                        <span>{requestDetails.custom_creatives_flag === 'E' ? 'Existing creatives' : requestDetails.custom_creatives_flag === 'M' ? 'I will make my own' : ''}</span>
                                    </td>
                                </tr>
                                {
                                    (requestDetails.custom_creatives_flag === 'M' || (requestDetails.custom_creatives_flag === 'E' && requestDetails.image)) &&
                                    <tr>
                                        <td>
                                            <span className='d-flex justify-content-between'>  
                                                {!tempFile && <img src={`${requestDetails.image}`} crossOrigin={process.env.REACT_APP_API_URL} alt="creative" style={{ maxWidth: '300px', border: '2px solid' }} />}
                                                <div>
                                                    {/* <a href={`${requestDetails.image}`} download={`${requestDetails.image}`} crossOrigin={process.env.REACT_APP_API_URL}>Download</a> */}

                                                    <button className='btn btn-link btn-sm' onClick={() => downloadImage(requestDetails.affiliate_id, requestDetails.offer_id, requestDetails.image)}>
                                                        Download
                                                    </button>
                                                </div>
                                            </span>
                                            {requestDetails.custom_creatives_flag === 'M' && <div className="alert alert-info mt-2">It may take up to 10 minutes for image to appear. If an image does not appear after 10 minutes, please reach out to your affiliate and have them send it via email.</div> }
                                        </td>
                                    </tr>
                                }                                
                                {/* <tr>
                                    <td>
                                        <form className='d-flex justify-content-between align-items-center' onSubmit={uploadCreative}>
                                            <span className='mr-2'>
                                                Upload Creative:
                                            </span>
                                            <span className="border rounded p-2">
                                                <input type="file" name="custom_creative" onChange={(e) => {
                                                    setTempFile(URL.createObjectURL(e.target.files[0]))
                                                }} />
                                                <button type='submit' className='btn btn-sm btn-primary'>
                                                    Upload
                                                </button>
                                            </span>
                                        </form>
                                        {tempFile && <div className="text-secondary">Preview: <img src={tempFile} alt="creative" style={{ maxWidth: '300px', border: '2px solid' }} /></div> }
                                    </td>
                                </tr>*/}
                                <tr>
                                    <td>
                                        <form onSubmit={saveAdditionalInfo}>
                                            <span className="d-block text-secondary">Additional Info:</span>
                                            <span>{requestDetails.description}</span>
                                            {/* <textarea name="description" rows={4} cols={40} className="form-control mb-2" defaultValue={requestDetails.description}></textarea>
                                            <button type='submit' className='float-right btn btn-primary'>Save Additional Info</button> */}
                                        </form>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default RequestDetails

// affiliate_id: 606420
// custom_answer_1: ""
// custom_answer_2: ""
// custom_answer_3: ""
// custom_creatives_flag: "E"
// description: "i will run this offer on taboola native ads network its paid native ads and quality traffic"
// estimated_leads: 100
// offer_id: 21827
// request_date: "2022-08-03T15:59:08.713Z"
// source_answer_1: "Taboola"
// source_answer_2: "Display"
// source_answer_3: ""
// status: "Open"
// traffic_type: "N"
