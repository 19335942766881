import moment from 'moment'
import { useState } from 'react'
import { Button, Card, Modal, Table } from 'react-bootstrap'

import Loading from '../../../../templates/UI/Loading/Loading'

import useCallApi from '../../../../hooks/useCallApi'
import EditIssue from './EditIssue/EditIssue'

const OpenIssues = ({ manager, advertiserId }) => {
    const [issueId, setIssueId] = useState()
    const [showIssueModal, setShowIssueModal] = useState(false)

    let apiUrl = !advertiserId ? `issues/${manager.id}` : `advertiser/${advertiserId}/issues`

    const { dataLoaded, data, refetch, setData } = useCallApi(apiUrl, 'GET', {}, [manager, advertiserId])

    const handleIssueClick = (issueId) => {
        setIssueId(issueId)
        setShowIssueModal(true)
    }

    const handleClose = (isEdited = false) => {
        setShowIssueModal(false)

        if (isEdited) {
            refetch()
        }
    }

    const getPriority = (priorityNum) => {
        if (priorityNum === 1) {
            return 'High'
        } else if (priorityNum === 2) {
            return 'Med'
        } else if (priorityNum === 3) {
            return 'Low'
        } else {
            return 'Campaign Req'
        }
    }

    const checkAlertFlag = (priority, update_date) => {
        if (priority <= 3) {
            let now = moment()
            let lastUpdateDate = moment(update_date)

            let age = now.diff(lastUpdateDate, 'days')

            let currentWeek = moment().week()
            let updateWeek = moment(update_date).week()

            if (updateWeek > currentWeek) {
                age = age - 2
            }

            if (priority === 1 && age >= 1) {
                return true
            } else if (priority === 2 && age >= 2) {
                return true
            } else if (priority === 3 && age >= 3) {
                return true
            }
        }
        return false
    }

    return (
        <Card className="mb-3">
            <Modal show={showIssueModal} size="lg" onHide={handleClose}>
                <EditIssue issueId={issueId} manager={manager}handleClose={handleClose} />
            </Modal>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>Open Issues</span>
                {!advertiserId && (
                    <Button variant="warning" size="sm" onClick={() => handleIssueClick(0)}>
                        Create Issue
                    </Button>
                )}
            </Card.Header>

            <Card.Body className="p-0 overflow-auto table-container-scroll">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>Priority</th>
                            <th>Opened</th>
                            <th>Opener</th>
                            <th>Updated</th>
                            <th>Assigned</th>
                            <th>Issue</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.length === 0 ? (
                                    <tr>
                                        <td colSpan={12}>No data.</td>
                                    </tr>
                                ) : (
                                    data.issues.map((issue) => (
                                        <tr key={issue.id} className={checkAlertFlag(issue.priority, issue.update_date) ? 'bgDanger25' : null}>
                                            <td xs={2}>{getPriority(issue.priority)}</td>
                                            <td xs={2}>{issue.open_date}</td>
                                            <td xs={2}>{issue.opened_admin.name.slice(0, issue.opened_admin.name.indexOf(' ') + 2)}</td>
                                            <td xs={2}>{issue.update_date}</td>
                                            <td xs={2}>{issue.assigned_admin.name.slice(0, issue.assigned_admin.name.indexOf(' ') + 2)}</td>
                                            <td xs={2}>
                                                <button className="btn btn-link text-left" to={''} onClick={() => handleIssueClick(issue.id)} dangerouslySetInnerHTML={{ __html: issue.issue }} />
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={6} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default OpenIssues
