import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Affiliate extends Component {
    state = {}
    render() {
        /*  Props:
                aid = affiliate ID
                status = status
                name = name
                priority = priority
            Allowed override props:
                status: highlight
        */

        let classes = 'mb_element'

        if (this.props.priority <= 2 || this.props.priority === 'medium' || this.props.priority === 'high' || this.props.priority === 'Medium' || this.props.priority === 'High') {
            classes = `mb-element highlight`
        }

        if (this.props.status) {
            if (this.props.status.toUpperCase() === 'CANCELLED' || this.props.status.toUpperCase() === 'DORMANT' || this.props.status.toUpperCase() === 'C' || this.props.status.toUpperCase() === 'L' || this.props.status.toUpperCase() === 'R') {
                classes = `mb-element cancelled`
            }
        }

        if (this.props.offerStatus) {
            if (this.props.offerStatus.toUpperCase() !== 'A') {
                classes = `mb-element rejected`
            }
        }

        if (this.props.green) {
            classes = `mb-element active`
        }

        let url = this.props.aid ? `/affiliate/${this.props.aid}` : ''

        // let classes = this.props.status ? `mb-element ${this.props.status}` : "mb-element";
        // let url = this.props.aid ? `https://ott.maxbounty.com/afffollowup.cfm?a=${this.props.aid}` : '';     

        return (
            <Link className={classes} to={{ pathname: url }}>
                <span className="name">
                    {this.props.name} {(this.props.affiliateStatus && this.props.affiliateStatus !== 'A') && <>⛔</>}
                </span>
            </Link>
        )
    }
}

export default Affiliate
