import React, { useCallback } from 'react'
import { Card, Form } from 'react-bootstrap'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import ReportTable from '../../../templates/ReportTable/ReportTable'

const DomainTable = ({ dataLoaded, domains, showSystemMessage, setFormData, formData, scope }) => {
    const handleChange = useCallback(
        (e) => {
            setFormData({ ...formData, selectedDomain: domains[parseInt(e.target.dataset.index)].domain_name })
        },
        [formData]
    )

    const totalClicks = useCallback(() => {
        const total = domains.reduce((acc, domain) => {
            return acc + domain.click_count
        }, 0)
        return total
    }, [domains])

    let processedTotals = [
        {
            type: 'none',
            value: '',
        },
        {
            type: 'none',
            value: '',
        },
        {
            type: 'none',
            value: '',
        },
        {
            type: 'none',
            value: '',
        },
        {
            type: 'none',
            value: '',
        },
        {
            type: 'none',
            value: '',
        }
    ]

    if (scope === 'custom') {
        processedTotals.push({
            type: 'none',
            value: '',
        })
        processedTotals.push({
            type: 'number',
            value: totalClicks(),
        })
    } else {
        processedTotals.push({
            type: 'number',
            value: totalClicks(),
        })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Domain URL',
                accessor: 'domain_name',
                Cell: (tableData) => {
                    let value = tableData.data[tableData.row.index].domain_name
                    return <Form.Check>
                        <Form.Check.Input type="radio" name="domains" id={`domain_${tableData.row.index}`} data-index={tableData.row.index} checked={formData.selectedDomain === value} onChange={handleChange} />
                        <Form.Check.Label className="ml-2" htmlFor={`domain_${tableData.row.index}`}>
                            {value}
                        </Form.Check.Label>
                    </Form.Check>
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }) => {
                    return <>{value}</>
                }
            },
            {
                Header: 'Priority',
                accessor: 'priority',
                Cell: ({ value }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate.name',
                Cell: (tableData) => {

                    let affiliateId = tableData.data[tableData.row.index].affiliate_id
                    let affiliate = tableData.data[tableData.row.index].affiliate

                    if (scope === 'custom') {
                        if (affiliateId && affiliateId > 0) {
                            return <Affiliate aid={affiliate.id} name={affiliate.name} status={affiliate.status.value} priority={affiliate.priority.value} />
                        } else {
                            return '-'
                        }
                    } else {
                        return ''
                    }
                },
                hide: scope === 'custom' ? false : true
            },
            {
                Header: 'Log Traffic',
                accessor: 'log_traffic',
                Cell: ({ value }) => {
                    return <>{value === 'Y' ? 'Yes' : 'No'}</>
                }
            },
            {
                Header: 'Cookie Support',
                accessor: 'cookies_supported',
                Cell: ({ value }) => {
                    return <>{value === 'Y' ? 'Yes' : 'No'}</>
                }
            },
            {
                Header: 'Domain Type',
                accessor: 'domain_type',
                Cell: ({ value }) => {
                    return <>{value}</>
                }
            },
            {
                Header: 'Domain Scope',
                accessor: 'domain_scope',
                Cell: ({ value }) => {
                    return <>{value}</>
                }
            },
            {
                Header: <div className='text-right'>Click Count</div>,
                accessor: 'click_count',
                className: 'text-right',
                Cell: ({ value }) => {
                    return <>{value.toLocaleString()}</>
                }
            }
        ]

        return (
            <React.Fragment>
                <ReportTable data={domains} columns={columns} totals={processedTotals} />
            </React.Fragment>
        )
    }

    return (
        <Card style={{ maxHeight: '100%' }}>
            <Card.Header>{scope === 'general' ? 'General Domains' : scope === 'custom' ? 'Custom Domains' : 'Unknown Scope Domains'}</Card.Header>
            {dataLoaded && (
                <Card.Body className="p-0">
                    {getReportOutput()}
                </Card.Body>
            )}
        </Card>
    )
}

export default DomainTable
