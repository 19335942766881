import React, { useState, useEffect } from 'react'
import Loading from '../../../../templates/UI/Loading/Loading'

import NewlyRunCampaigns from '../NewlyRunCampaigns/NewlyRunCampaigns'
import TopAffiliates from '../TopAffiliates/TopAffiliates'
import AffiliateHealthSummary from '../AffiliateHealthSummary/AffiliateHealthSummary'
import AffiliateTeam from '../AffiliateTeam/AffiliateTeam'
import MarginSummary from '../MarginSummary/MarginSummary'

import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import HMTransferToSrAM from '../HMTransferToSrAM/HMTransferToSrAM'
import CampaignSliders from '../CampaignSliders/CampaignSliders'
import AffiliateCampaignPickups from '../AffiliateCampaignPickups/AffiliateCampaignPickups'
import CampaignRequests from '../CampaignRequests/CampaignRequests'
import OpenIssues from '../OpenIssues/OpenIssues'
import AffiliateEarningChanges from '../AffiliateEarningChanges/AffiliateEarningChanges'

export default function AffiliateDirector({ manager, setManager, showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_director_dashboard')

    const [dataLoaded, setDataLoaded] = useState(true)

    return (
        <React.Fragment>
            {dataLoaded ? (
                <React.Fragment>
                    <div className="page-header d-flex align-items-center">
                        <h1 className="mb-h1">Affiliate Director</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <MarginSummary showSystemMessage={showSystemMessage} manager={manager} />
                            <AffiliateHealthSummary showSystemMessage={showSystemMessage} />
                            <HMTransferToSrAM manager={manager} showSystemMessage={showSystemMessage} />
                            <CampaignSliders showSystemMessage={showSystemMessage} />
                        </div>
                        <div className="col-lg-8">
                            <AffiliateTeam showSystemMessage={showSystemMessage} mode="all" />
                            <div className="row">
                                <div className="col-lg-6">
                                    <OpenIssues manager={manager} showSystemMessage={showSystemMessage} />
                                    <NewlyRunCampaigns />
                                    <CampaignRequests manager={manager} showSystemMessage={showSystemMessage} mode={'all'} />
                                </div>
                                <div className="col-lg-6">
                                    <TopAffiliates />
                                    <AffiliateCampaignPickups />
                                    <AffiliateEarningChanges showSystemMessage={showSystemMessage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}
