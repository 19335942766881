import callApi from '../../helpers/callAPI'
import React, { useCallback, useState } from 'react'
import { Button, Card, Form, Modal, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useCallApi from '../../hooks/useCallApi'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Campaign from '../../templates/Campaign/Campaign'
import Loading from '../../templates/UI/Loading/Loading'
import CallBacksForAfilliateModal from '../CallbacksModal/CallbacksModal'
import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'
import ReportTable from '../../templates/ReportTable/ReportTable'
import { useAuth, useProtectedPage } from '../../context/AuthContext'

const Callbacks = ({ affiliateId, campaignId, showSystemMessage }) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_callbacks')

    const [isOpen, setIsOpen] = useState(false)

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const { dataLoaded, data, setData, refetch } = useCallApi(affiliateId ? `affiliate/${affiliateId}/callbacks` : `campaign/${campaignId}/callbacks`, 'GET', {}, [affiliateId, campaignId])

    document.title = `${dataLoaded ? `${affiliateId ? data.affiliate.name : data.campaign.name}: ` : ''}Callbacks`

    const updateCallback = useCallback(
        async (index, e) => {
            e.preventDefault()

            const form = {
                code: e.target.callback_code.value,
                type: e.target.callback_type.value,
            }
            const res = await callApi(affiliateId ? `affiliate/${affiliateId}/${data.callbacks[index].campaign.id}/callbacks` : `campaign/${campaignId}/${data.callbacks[index].affiliate.id}/callbacks`, 'PUT', {
                ...form,
            })
            if (res.success) {
                refetch()
                showSystemMessage('success', 'Callback updated')
            } else {
                showSystemMessage('error', res.message)
            }
        },
        [affiliateId, refetch, showSystemMessage, data]
    )

    const deleteCallback = useCallback(
        async (index) => {
            const res = await callApi(affiliateId ? `affiliate/${affiliateId}/${data.callbacks[index].campaign.id}/callbacks` : `campaign/${campaignId}/${data.callbacks[index].affiliate.id}/callbacks`, 'DELETE', {})
            if (res.success) {
                refetch()
                showSystemMessage('success', 'Callback deleted')
            } else {
                showSystemMessage('error', res.message)
            }
        },
        [affiliateId, refetch, showSystemMessage, data]
    )

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: affiliateId ? 'campaign.id' : 'affiliate.id',
                Cell: ({ value }) => {
                    return <>{value}</>
                },
            },
            {
                Header: affiliateId ? 'Campaign' : 'Affiliate',
                accessor: affiliateId ? 'campaign.name' : 'affiliate.name',
                Cell: (tableData) => {
                    return affiliateId ? <Campaign oid={tableData.data[tableData.row.index].id} name={tableData.data[tableData.row.index].campaign.name} status={tableData.data[tableData.row.index].campaign.status} /> : <Affiliate aid={tableData.data[tableData.row.index].id} name={tableData.data[tableData.row.index].affiliate.name} status={tableData.data[tableData.row.index].affiliate.status} priority={tableData.data[tableData.row.index].affiliate.priority} />
                },
            },
            {
                Header: affiliateId ? 'Campaign Status' : 'Affiliate Status',
                accessor: 'status',
                Cell: (tableData) => {
                    return <>{affiliateId ? tableData.data[tableData.row.index].campaign.status : tableData.data[tableData.row.index].affiliate.status}</>
                },
            },
            {
                Header: affiliateId ? 'Advertiser Manager' : 'Affiliate Manager',
                accessor: 'manager.mgr_name',
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].manager.mgr_name}</>
                },
            },
            {
                Header: 'Callback Code',
                accessor: 'callback.code',
                Cell: (tableData) => {
                    let currentId = affiliateId ? tableData.data[tableData.row.index].campaign.id : tableData.data[tableData.row.index].affiliate.id
                    return (
                        <form className="d-flex align-items-center" onSubmit={(e) => updateCallback(tableData.row.index, e)}>
                            <select
                                defaultValue={tableData.data[tableData.row.index].callback.type}
                                name="callback_type"
                                className="form-control w-50 mr-4"
                                // onChange={(e) => {
                                //     let currentId = affiliateId ? tableData.data[tableData.row.index].campaign.id : tableData.data[tableData.row.index].affiliate.id
                                //     setFocusId(currentId)
                                //     setData((prev) => {
                                //         return {
                                //             ...prev,
                                //             callbacks: prev.callbacks.map((c, i) => (tableData.row.index === i ? { ...c, callback: { ...c.callback, type: e.target.value } } : c)),
                                //         }
                                //     })
                                // }}
                            >
                                <option value="S">Server (delayed)</option>
                                <option value="L">URL (pixel)</option>
                                <option value="I">HTML/Javascript (iframe)</option>
                            </select>
                            <textarea name="callback_code" key={currentId} className="form-control mr-4" defaultValue={tableData.data[tableData.row.index].callback.code} />
                            <button type="submit" className="btn btn-primary" name="action">
                                Save
                            </button>
                            &nbsp; &nbsp; &nbsp;
                            <button type="button" className="btn btn-danger" onClick={() => deleteCallback(tableData.row.index)}>
                                Delete
                            </button>
                        </form>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.callbacks} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <CallBacksForAfilliateModal refetch={refetch} isOpen={isOpen} setIsOpen={setIsOpen} affiliate={data.affiliate} campaign={data.campaign} showSystemMessage={showSystemMessage} />
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Callbacks</h1>
                <div className="ml-5">{affiliateId ? <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <div className="card">
                <div className="d-flex justify-content-between p-3 align-items-center">
                    <h1 className="mb-h1-dark mr-2">
                        Callbacks for {affiliateId ? 'Affiliate' : 'Campaign'}: {dataLoaded ? affiliateId ? <Affiliate aid={data.affiliate.id} name={data.affiliate.name} priority={data.affiliate.priority.value} status={data.affiliate.status.value} /> : <Campaign oid={data.campaign.id} name={data.campaign.name} status={data.campaign.status.value} /> : spinner}
                    </h1>
                    <button type="button" className="btn btn-warning" onClick={() => setIsOpen(true)}>
                        Add Callback
                    </button>
                </div>
                {dataLoaded ? data.callbacks.length === 0 ? <div className="p-3">No stats for the current parameters</div> : getReportOutput() : <Loading />}
            </div>
        </div>
    )
}

export default Callbacks
