import React, { useState, useEffect } from 'react';
import { Mail, Smartphone, User, MessageCircle } from 'react-feather'
import { FaSkype } from 'react-icons/fa'
import AdvertiserAdditionalContactModal from '../../../../components/AdvertiserAdditionalContacts/AdvertiserAdditionalContactModal'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext';
import AdvertiserAdditionalContacts from '../../../../components/AdvertiserAdditionalContacts/AdvertiserAdditionalContacts';

const AdvertiserContactInfo = ({ advertiserId, contactInfo, additionalContacts, email, contact, refetch }) => {

    const auth = useAuth()
    let showAdvertiserSettings = useProtectedContent(auth.permissions, 'advertiser_settings')

    let imType = ''

    if (contactInfo.im_type === 'A' || contactInfo.im_type === 'M') {
        imType = <><MessageCircle size={14} fill="black" /> &nbsp;{contactInfo.im_id ? contactInfo.im_id : 'N/A'} ({contactInfo.im_type === 'A' ? 'AIM' : 'MSN'})</>
    } else if (contactInfo.im_type === 'S') {
        imType = <><FaSkype size={18} color="#007bff" />
            &nbsp;
            {contactInfo.im_id ? <a href={`skype:${contactInfo.im_id}?chat`}>{contactInfo.im_id}</a> : 'N/A'}</>
    } else if (contactInfo.im_type === 'Y') {
        imType = <>
            <svg xmlns="http://www.w3.org/2000/svg" height="1.25em" viewBox="0 0 512 512" color='#4A00A0'><path d="M223.69,141.06,167,284.23,111,141.06H14.93L120.76,390.19,82.19,480h94.17L317.27,141.06Zm105.4,135.79a58.22,58.22,0,1,0,58.22,58.22A58.22,58.22,0,0,0,329.09,276.85ZM394.65,32l-93,223.47H406.44L499.07,32Z" /></svg>
            &nbsp;
            {contactInfo.im_id ? contactInfo.im_id : 'N/A'}</>
    } else if (contactInfo.im_type === 'N') {
        imType = <><MessageCircle size={14} fill="black" /> &nbsp;{contactInfo.im_id ? contactInfo.im_id : 'N/A'} (Other)</>
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-lg-between flex-wrap p-2">
                <div className="px-1 align-items-center d-flex">
                    <User size={13} fill="black" />
                    &nbsp;
                    {contact}
                </div>
                <div className="px-1 align-items-center d-flex">
                    {imType}
                </div>
                <div className="px-1 align-items-center d-flex">
                    <Smartphone size={18} />
                    {contactInfo.mobile_phone ? contactInfo.mobile_phone : contactInfo.phone ? contactInfo.phone : 'N/A'}{(contactInfo.mobile_phone && contactInfo.phone) && <> / {contactInfo.phone}</>}
                </div>
                <div className="px-1 d-flex align-items-center">
                    <a href={`mailto:${email}`}>
                        <Mail size={20} style={{ paddingRight: '5px' }} />
                        Email
                    </a>
                </div>
            </div>
            <AdvertiserAdditionalContacts advertiserId={advertiserId} additionalContacts={additionalContacts} refetch={refetch} />
        </>
    )
}

export default AdvertiserContactInfo
