import React from 'react'
import { Mail, MessageCircle, Smartphone } from 'react-feather'
import { FaSkype, FaExternalLinkAlt } from 'react-icons/fa'
import convertUrl from '../../../../helpers/convertUrl'
import Pill from '../../../../templates/UI/Pill/Pill'

const AffiliatePaymentInfo = ({payment}) => {

    return (
        <div className="p-2 border-top">
            Payout Details <Pill borderColor={payment.platform ? '#17a2b8' : ''} text={payment.platform ? payment.platform.toUpperCase() : 'Not Set'} /> : <Pill borderColor={payment.method.method ? '#17a2b8' : ''} text={payment.method.method ? payment.method.method : 'Not Set'} />        
        </div>
    )
}

export default AffiliatePaymentInfo
