import React, { Component, useEffect, useState } from 'react'
import './Reports.css'
import queryString from 'query-string'
import moment from 'moment'

import ReportAffiliateEarnings from './ReportAffiliateEarnings/ReportAffiliateEarnings'
import ReportCampaignEarnings from './ReportCampaignEarnings/ReportCampaignEarnings'
import ReportAdvertiserEarnings from './ReportAdvertiserEarnings/ReportAdvertiserEarnings'
import ReportMerchantMargin from './ReportMerchantMargin/ReportMerchantMargin'
import ReportClicks from './ReportClicks/ReportClicks'
import ReportCreatives from './ReportCreatives/ReportCreatives'
import ReportLandingPages from './ReportLandingPages/ReportLandingPages'
import ReportAffSignup from './ReportAffSignup/ReportAffSignup'
import ReportSotu from './ReportSotu/ReportSotu'
import ReportMargin from './ReportMargin/ReportMargin'
import ReportAffPaymentTotals from './ReportAffPaymentTotals/ReportAffPaymentTotals'
import ReportAffPayTotalsDetails from './ReportAffPayTotalsDetails/ReportAffPayTotalsDetails'
import ReportTrends from './ReportTrends/ReportTrends'
import ReportTrendDetail from './ReportTrendDetail/ReportTrendDetail'
import ReportInvoice from './ReportInvoice/ReportInvoice'
import ReportInvoiceList from './ReportInvoiceList/ReportInvoiceList'
import ReportMerchantBalances from './ReportMerchantBalances/ReportMerchantBalances'
import ReportBizDevPipeline from './ReportBizDevPipeline/ReportBizDevPipeline'

import NameFilter from './Filters/NameFilter/NameFilter'
import DateRangeFilter from './Filters/DateRangeFilter/DateRangeFilter'
import CountryFilter from './Filters/CountryFilter/CountryFilter'
import ManagerDropdown from '../../templates/ManagerDropdown/ManagerDropdown'
import ReportReferringUrls from './ReportReferringUrls/ReportReferringUrls'
import ReportSubId from './ReportSubId/ReportSubId'
import Checkbox from './Filters/Checkbox/Checkbox'
import CampaignTrendFilters from './Filters/TrendFilters/TrendFilters'
import HeaderStats from '../../templates/Layout/HeaderStats/HeaderStats'
import ReportTestPostbacks from './TestPostbacks/TestPostbacks'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuth, useSetManager } from '../../context/AuthContext'

export default function Reports(props) {

    const auth = useAuth()
    const showMarginSetManager = useSetManager(auth.permissions, 'report_margin')

    const [oldState, setOldState] = useState({
        dataLoaded: false,
        type: '',
        mgrId: '',
        bizDevMgrId: '',
        timeFrame: 'd',
        filterTrend: 'b',
        minT1Margin: '',
        filterTrendDate: '',
        affiliateId: '',
        offerId: '',
        merchantId: '',
        creativeId: '',
        landingPageId: '',
        subId: '',
        affiliatePriority: '',
        affiliateStatus: '',
        totalAppsFlag: false,
        startDate: '',
        endDate: '',
        quickDate: 'month',
        selectedType: '',
        selectedStartDate: '',
        selectedEndDate: '',
        filterStartDate: '',
        filterEndDate: '',
        preparingCSV: false,
        CSVData: '',
        page: 1,
        totalPages: 1,
        resultsPerPage: 50,
        payable: false,
        waiting: false,
        revshare: false,
        perlead: false,
    })

    const [isMounted, setIsMounted] = useState(false)
    const location = useLocation()

    useEffect(() => {
        setIsMounted(true)

        let urlParams = queryString.parse(location.search)
        let mgrId
        let bizDevMgrId
        let minT1Margin
        let filterTrendDate
        let timeFrame
        let filterTrend
        let affiliateId
        let offerId
        let merchantId
        let creativeId
        let landingPageId
        let subId
        let affiliatePriority
        let affiliateStatus
        let totalAppsFlag
        let startDate
        let endDate
        let quickDate
        let filterStartDate
        let filterEndDate
        let type
        let page
        let payable
        let waiting
        let perlead
        let revshare

        // Get the report type if specified. Get the offer ID if specified.
        type = props ? props.type : 'affiliate_earnings'

        //Get the manager ID from the URL
        mgrId = auth.user.id

        if (showMarginSetManager) {
            mgrId = urlParams.mgrid ? parseInt(urlParams.mgrid) : auth.user.id
        }

        if (type === 'campaignTrend' || type === 'affiliateTrend') {
            mgrId = urlParams.mgrid ? parseInt(urlParams.mgrid) : 0
        }

        // Get the bizdev manager ID from the URL - Used in Campaign Trend Report
        bizDevMgrId = urlParams.bmid ? parseInt(urlParams.bmid) : 0

        timeFrame = urlParams.t ? urlParams.t.trim() : 'd'

        filterTrend = urlParams.filter_trend ? urlParams.filter_trend.trim() : 'b'

        minT1Margin = urlParams.mint1 ? urlParams.mint1.trim() : ''

        filterTrendDate = urlParams.date ? urlParams.date.trim() : ''
        // Get the affiliate ID from the URL.
        affiliateId = urlParams.aid ? parseInt(urlParams.aid) : 0

        // Get the offer ID from the URL.
        offerId = urlParams.oid ? parseInt(urlParams.oid) : 0

        // Get the offer ID from the URL.
        merchantId = urlParams.mid ? parseInt(urlParams.mid) : 0

        // Get the creative ID from the URL.
        creativeId = urlParams.cid ? parseInt(urlParams.cid) : ''

        // Get the landing page ID from the URL.
        landingPageId = urlParams.lpid ? parseInt(urlParams.lpid) : ''

        // Get the affiliate ID from the URL.
        subId = urlParams.subid ? urlParams.subid : ''

        // Get the affiliate priority from the URL.
        affiliatePriority = urlParams.ap ? parseInt(urlParams.ap) : ''

        // Get the affiliate status from the URL.
        affiliateStatus = urlParams.as ? urlParams.as : ''

        // Get the total apps flag from the URL.
        totalAppsFlag = urlParams.ta ? urlParams.ta : 'false'

        // Get the date from URL. If there's nothing, then use today.
        if (urlParams.startDate && urlParams.endDate) {
            startDate = urlParams.startDate
            endDate = urlParams.endDate
        } else {
            startDate = moment().startOf('month').format('YYYY-MM-DD')
            endDate = moment().endOf('day').format('YYYY-MM-DD')
        }

        // Get the filter dates from URL. If there's nothing, let it be empty.
        if (urlParams.filterStartDate && urlParams.filterEndDate) {
            filterStartDate = urlParams.filterStartDate
            filterEndDate = urlParams.filterEndDate
        }

        // Get payable, waiting, revshare, perlead for Margin Report

        payable = urlParams.payable ? true : false
        waiting = urlParams.waiting ? true : false
        revshare = urlParams.revshare ? true : false
        perlead = urlParams.perlead ? true : false

        /* Get the page number from the URL. If none, then use 1. NOTE: The API starts at page 0, so we'll need to subtract 1 before
           fetching from the API */
        if (urlParams.page) {
            page = parseInt(urlParams.page)
        } else {
            page = 1
        }

        let startRow = (page - 1) * oldState.resultsPerPage
        let endRow = page * oldState.resultsPerPage - 1

        // See if the dates are one of the quick dates
        if (startDate === moment().startOf('month').format('YYYY-MM-DD') && endDate === moment().endOf('month').format('YYYY-MM-DD')) {
            quickDate = 'month'
        } else if (startDate === moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') && endDate === moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')) {
            quickDate = 'lastMonth'
        } else if (startDate === moment().startOf('day').format('YYYY-MM-DD') && endDate === moment().startOf('day').format('YYYY-MM-DD')) {
            quickDate = 'today'
        } else if (startDate === moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD') && endDate === moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD')) {
            quickDate = 'yesterday'
        } else if (startDate === moment().day(1).subtract(1, 'day').format('YYYY-MM-DD') && endDate === moment().day(1).add(5, 'days').format('YYYY-MM-DD')) {
            quickDate = 'thisPayPeriod'
        } else if (startDate === moment().day(1).subtract(8, 'days').format('YYYY-MM-DD') && endDate === moment().day(1).subtract(2, 'days').format('YYYY-MM-DD')) {
            quickDate = 'thisPayPeriod'
        } else {
            quickDate = 'custom'
        }

        // Update the state with the chosen dates
        setOldState({
            ...oldState,
            dataLoaded: true,
            type: type,
            mgrId: mgrId,
            bizDevMgrId: bizDevMgrId,
            minT1Margin: minT1Margin,
            filterTrendDate: filterTrendDate,
            affiliateId: affiliateId,
            timeFrame: timeFrame,
            filterTrend: filterTrend,
            offerId: offerId,
            merchantId: merchantId,
            creativeId: creativeId,
            landingPageId: landingPageId,
            subId: subId,
            affiliatePriority: affiliatePriority,
            affiliateStatus: affiliateStatus,
            totalAppsFlag: totalAppsFlag,
            startDate: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(endDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            quickDate: quickDate,
            selectedType: type,
            selectedStartDate: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            selectedEndDate: moment(endDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            filterStartDate: filterStartDate ? moment(filterStartDate).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '',
            filterEndDate: filterEndDate ? moment(filterEndDate).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '',
            page: page,
            startRow: startRow,
            endRow: endRow,
            payable: payable,
            waiting: waiting,
            revshare: revshare,
            perlead: perlead,
        })
    }, [location])

    const handleManagerChange = (mgrid) => {
        if (isMounted) {
            setOldState({ ...oldState, mgrId: parseInt(mgrid) })
        }
    }

    // Need this separate function for Campaign Trend Report ONLY since it needs both mmId and BizDevId at the same time. For everything else, handleManagerChange would work.
    const handleBizDevManagerChange = (bizMgrId) => {
        if (isMounted) {
            setOldState({ ...oldState, bizDevMgrId: parseInt(bizMgrId) })
        }
    }

    // Campaign Trend Report specific function
    const handleFilterChange = (source, value) => {
        if (source === 'timeframe') {
            setOldState({ ...oldState, timeFrame: value })
        } else if (source === 'filter_trend') {
            setOldState({ ...oldState, filterTrend: value })
        }
    }

    // Campaign Trend Filters
    const handleResetFilters = (e) => {
        setOldState({ ...oldState, timeFrame: 'd', filterTrend: 'b', mgrId: '', bizDevMgrId: '', minT1Margin: '', filterTrendDate: '' }, () => handleReport(e))
    }

    const handleT1MinMarrgin = (minMargin) => {
        setOldState({ ...oldState, minT1Margin: minMargin })
    }

    const handleDateSelect = (date) => {
        setOldState({ ...oldState, filterTrendDate: date })
    }

    const handleChangeStart = (date) => {
        if (isMounted) {
            setOldState({
                ...oldState,
                selectedStartDate: date,
            })
        }
    }

    const handleChangeEnd = (date) => {
        if (isMounted) {
            setOldState({
                ...oldState,
                selectedEndDate: date,
            })
        }
    }

    const checkboxChangeHandler = (event) => {
        setOldState({ ...oldState, [event.target.id]: event.target.checked })
    }

    const handleQuickDate = (event) => {
        let selectedStartDate = oldState.selectedStartDate
        let selectedEndDate = oldState.selectedEndDate

        switch (event.target.value) {
            case 'month':
                selectedStartDate = moment().startOf('month').format('YYYY-MM-DD')
                selectedEndDate = moment().endOf('month').format('YYYY-MM-DD')
                break
            case 'lastMonth':
                selectedStartDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
                selectedEndDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                break
            case 'today':
                selectedStartDate = moment().startOf('day').format('YYYY-MM-DD')
                selectedEndDate = moment().endOf('day').format('YYYY-MM-DD')
                break
            case 'yesterday':
                selectedStartDate = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
                selectedEndDate = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')
                break
            case 'thisPayPeriod':
                selectedStartDate = moment().day(1).subtract(1, 'day').format('YYYY-MM-DD')
                selectedEndDate = moment().day(1).add(5, 'days').format('YYYY-MM-DD')
                break
            case 'lastPayPeriod':
                selectedStartDate = moment().day(1).subtract(8, 'days').format('YYYY-MM-DD')
                selectedEndDate = moment().day(1).subtract(2, 'days').format('YYYY-MM-DD')
                break
            default:
        }

        if (isMounted) {
            setOldState({
                ...oldState,
                quickDate: event.target.value,
                selectedStartDate: selectedStartDate,
                selectedEndDate: selectedEndDate,
            })
        }
    }

    /* This will apply the settings based on the selected options */
    const handleReport = (e) => {
        e.preventDefault()
        let startDate = oldState.type !== 'campaignTrend' || oldState.type !== 'affiliateTrend' ? moment(oldState.selectedStartDate).startOf('day').format('YYYY-MM-DD') : ''
        let endDate = oldState.type !== 'campaignTrend' || oldState.type !== 'affiliateTrend' ? moment(oldState.selectedEndDate).startOf('day').format('YYYY-MM-DD') : ''

        let type = oldState.type
        let mgrId = oldState.mgrId
        let bizDevMgrId = oldState.bizDevMgrId
        let timeFrame = oldState.timeFrame
        let filterTrend = oldState.filterTrend
        let filterTrendDate = oldState.filterTrendDate
        let minT1Margin = oldState.minT1Margin
        let affiliateId = oldState.affiliateId
        let offerId = oldState.offerId
        let merchantId = oldState.merchantId
        let subId = oldState.subId
        let affiliatePriority = oldState.affiliatePriority
        let affiliateStatus = oldState.affiliateStatus
        let totalAppsFlag = oldState.totalAppsFlag
        let creativeId = oldState.creativeId
        let landingPageId = oldState.landingPageId
        let page = 1
        let startRow = (page - 1) * oldState.resultsPerPage
        let endRow = page * oldState.resultsPerPage - 1
        let filterStartDate = oldState.filterStartDate ? moment(oldState.filterStartDate).startOf('day').format('YYYY-MM-DD') : ''
        let filterEndDate = oldState.filterEndDate ? moment(oldState.filterEndDate).startOf('day').format('YYYY-MM-DD') : ''
        let payable = oldState.payable
        let waiting = oldState.waiting
        let revshare = oldState.revshare
        let perlead = oldState.perlead

        if (isMounted) {
            setOldState({
                ...oldState,
                startDate: startDate,
                endDate: endDate,
                type: type,
                page: page,
                startRow: startRow,
                endRow: endRow,
            })
        }

        let url = oldState.type !== 'campaignTrend' && oldState.type !== 'affiliateTrend' ? `/reports/${type}?startDate=${startDate}&endDate=${endDate}` : `/reports/${type}?t=${timeFrame}&filter_trend=${filterTrend}`

        if (mgrId > 0) {
            url = url + `&mgrid=${mgrId}`
        }

        if (bizDevMgrId > 0) {
            url = url + `&bmid=${bizDevMgrId}`
        }

        if (minT1Margin.trim() !== '') {
            url = url + `&mint1=${minT1Margin}`
        }

        if (filterTrendDate !== '') {
            url = url + `&date=${moment(filterTrendDate).startOf('day').format('YYYY-MM-DD')}`
        }

        if (affiliateId > 0) {
            url = url + `&aid=${affiliateId}`
        }

        if (offerId > 0) {
            url = url + `&oid=${offerId}`
        }

        if (merchantId > 0) {
            url = url + `&mid=${merchantId}`
        }

        if (creativeId > 0) {
            url = url + `&cid=${creativeId}`
        }

        if (landingPageId > 0) {
            url = url + `&lpid=${landingPageId}`
        }

        if (subId > 0) {
            url = url + `&subid=${subId}`
        }

        if (affiliatePriority > 0) {
            url = url + `&ap=${affiliatePriority}`
        }

        if (affiliateStatus !== '') {
            url = url + `&as=${affiliateStatus}`
        }

        if (totalAppsFlag === 'true') {
            url = url + `&ta=${totalAppsFlag}`
        }

        if (filterStartDate !== '') {
            url = url + `&filterStartDate=${filterStartDate}`
        }

        if (filterEndDate !== '') {
            url = url + `&filterEndDate=${filterEndDate}`
        }

        if (payable) {
            url = url + `&payable=true`
        }

        if (waiting) {
            url = url + `&waiting=true`
        }
        if (revshare) {
            url = url + `&revshare=true`
        }
        if (perlead) {
            url = url + `&perlead=true`
        }
        props.history.push(url)
    }

    let useFilters = true
    let reportToDisplay
    let reportTitle
    let nameFilterDisplay = false
    let countryFilterDisplay = false
    let marginsFilterDisplay = false
    let managerDropdownDisplay = true
    let dateRangeDisplay = true
    let mgrType

    switch (oldState.type) {
        case 'affiliate_earnings':
            reportTitle = 'Affiliate Earnings'
            mgrType = 'affiliate'
            countryFilterDisplay = false
            reportToDisplay = <ReportAffiliateEarnings key={`${oldState.startDate} - ${oldState.endDate}`} mgrid={oldState.mgrId} oid={oldState.offerId} ap={oldState.affiliatePriority} as={oldState.affiliateStatus} ta={oldState.totalAppsFlag} startDate={oldState.startDate} endDate={oldState.endDate} filterStartDate={oldState.filterStartDate} filterEndDate={oldState.filterEndDate} startRow={oldState.startRow} endRow={oldState.endRow} showSystemMessage={props.showSystemMessage} />
            break
        case 'campaign_earnings':
            reportTitle = 'Campaign Earnings'
            mgrType = 'bizdev'
            countryFilterDisplay = false
            reportToDisplay = <ReportCampaignEarnings key={`${oldState.startDate} - ${oldState.endDate}`} aid={oldState.affiliateId} mid={oldState.merchantId} startDate={oldState.startDate} endDate={oldState.endDate} startRow={oldState.startRow} endRow={oldState.endRow} showSystemMessage={props.showSystemMessage} />
            break
        case 'advertiser_earnings':
            reportTitle = 'Advertiser Earnings'
            countryFilterDisplay = false
            reportToDisplay = <ReportAdvertiserEarnings key={`${oldState.startDate} - ${oldState.endDate}`} startDate={oldState.startDate} endDate={oldState.endDate} startRow={oldState.startRow} endRow={oldState.endRow} showSystemMessage={props.showSystemMessage} />
            break
        case 'merchant_margin':
            reportTitle = 'Advertiser Margin'
            mgrType = 'merchant'
            nameFilterDisplay = false
            countryFilterDisplay = false
            reportToDisplay = <ReportMerchantMargin key={`${oldState.startDate} - ${oldState.endDate}`} mgrid={oldState.mgrId} startDate={oldState.startDate} endDate={oldState.endDate} startRow={oldState.startRow} endRow={oldState.endRow} showSystemMessage={props.showSystemMessage} />
            break
        case 'sotu':
            reportTitle = 'State of the Union'
            reportToDisplay = <ReportSotu showSystemMessage={props.showSystemMessage} />
            useFilters = false
            break
        case 'clicks':
            reportTitle = 'Clicks'
            managerDropdownDisplay = false
            countryFilterDisplay = false
            reportToDisplay = <ReportClicks key={`${oldState.startDate} - ${oldState.endDate}`} aid={oldState.affiliateId} oid={oldState.offerId} cid={oldState.creativeId} lpid={oldState.landingPageId} startDate={oldState.startDate} endDate={oldState.endDate} startRow={oldState.startRow} endRow={oldState.endRow} showSystemMessage={props.showSystemMessage} />
            break
        case 'postbacks':
            reportTitle = 'Test Postbacks'
            managerDropdownDisplay = false
            reportToDisplay = <ReportTestPostbacks key={`${oldState.startDate} - ${oldState.endDate}`} oid={oldState.offerId} startDate={oldState.startDate} endDate={oldState.endDate} showSystemMessage={props.showSystemMessage} ta={false} />
            break
        case 'creatives':
            reportTitle = 'Creatives'
            managerDropdownDisplay = false
            countryFilterDisplay = false
            reportToDisplay = <ReportCreatives key={`${oldState.startDate} - ${oldState.endDate}`} aid={oldState.affiliateId} oid={oldState.offerId} startDate={oldState.startDate} endDate={oldState.endDate} startRow={oldState.startRow} endRow={oldState.endRow} showSystemMessage={props.showSystemMessage} />
            break
        case 'landing_pages':
            reportTitle = 'Landing Pages'
            managerDropdownDisplay = false
            countryFilterDisplay = false
            reportToDisplay = <ReportLandingPages key={`${oldState.startDate} - ${oldState.endDate}`} aid={oldState.affiliateId} oid={oldState.offerId} startDate={oldState.startDate} endDate={oldState.endDate} startRow={oldState.startRow} endRow={oldState.endRow} showSystemMessage={props.showSystemMessage} />
            break
        case 'referring_urls_clicks':
            reportTitle = 'Referring URLs'
            managerDropdownDisplay = false
            countryFilterDisplay = false
            reportToDisplay = <ReportReferringUrls key={`${oldState.startDate} - ${oldState.endDate}`} aid={oldState.affiliateId} oid={oldState.offerId} cid={oldState.creativeId} lpid={oldState.landingPageId} startDate={oldState.startDate} endDate={oldState.endDate} startRow={oldState.startRow} endRow={oldState.endRow} showSystemMessage={props.showSystemMessage} />
            break
        case 'subids':
            reportTitle = 'Sub IDs'
            managerDropdownDisplay = false
            countryFilterDisplay = false
            reportToDisplay = <ReportSubId key={`${oldState.startDate} - ${oldState.endDate}`} aid={oldState.affiliateId} oid={oldState.offerId} startDate={oldState.startDate} endDate={oldState.endDate} startRow={oldState.startRow} endRow={oldState.endRow} showSystemMessage={props.showSystemMessage} />
            break
        case 'affiliate_signup':
            reportTitle = 'Affiliate Health'
            mgrType = 'affiliate'
            nameFilterDisplay = false
            countryFilterDisplay = false
            reportToDisplay = <ReportAffSignup key={`${oldState.startDate} - ${oldState.endDate}`} mgrid={oldState.mgrId} startDate={oldState.startDate} endDate={oldState.endDate} showSystemMessage={props.showSystemMessage} />
            break
        case 'margin':
            reportTitle = 'Margin'
            nameFilterDisplay = false
            countryFilterDisplay = false
            marginsFilterDisplay = true
            reportToDisplay = <ReportMargin key={`${oldState.startDate} - ${oldState.endDate}`} mgrId={oldState.mgrId} startDate={oldState.startDate} endDate={oldState.endDate} showSystemMessage={props.showSystemMessage} />
            break
        case 'affiliatePaymentTotals':
            reportTitle = 'Affiliate Payment Totals'
            reportToDisplay = <ReportAffPaymentTotals showSystemMessage={props.showSystemMessage} />
            useFilters = false
            break
        case 'affiliatePaymentTotalsDetails':
            reportTitle = 'Affiliate Payment Totals Details'
            reportToDisplay = <ReportAffPayTotalsDetails showSystemMessage={props.showSystemMessage} />
            useFilters = false
            break
        case 'campaignTrend':
            reportTitle = 'Campaign Trend'
            managerDropdownDisplay = false
            nameFilterDisplay = false
            dateRangeDisplay = false
            reportToDisplay = <ReportTrends showSystemMessage={props.showSystemMessage} type="campaign" />
            break
        case 'campaignTrendDetail':
            reportTitle = 'Campaign Trend Detail'
            useFilters = false
            reportToDisplay = <ReportTrendDetail showSystemMessage={props.showSystemMessage} type="campaign" />
            break
        case 'affiliateTrend':
            reportTitle = 'Affiliate Trend'
            managerDropdownDisplay = false
            nameFilterDisplay = false
            dateRangeDisplay = false
            reportToDisplay = <ReportTrends showSystemMessage={props.showSystemMessage} type="affiliate" />
            break
        case 'affiliateTrendDetail':
            reportTitle = 'Affiliate Trend Detail'
            useFilters = false
            reportToDisplay = <ReportTrendDetail showSystemMessage={props.showSystemMessage} type="affiliate" />
            break
        case 'invoice_report':
            reportTitle = 'Invoice Report'
            dateRangeDisplay = true
            managerDropdownDisplay = false
            reportToDisplay = <ReportInvoice showSystemMessage={props.showSystemMessage} startDate={oldState.startDate} endDate={oldState.endDate} />
            break
        case 'list_invoices':
            reportTitle = 'Current Invoices To Be Done'
            useFilters = false
            reportToDisplay = <ReportInvoiceList showSystemMessage={props.showSystemMessage} />
            break
        case 'advertiserBalances':
            reportTitle = 'Pre-paid Merchant Balances For'
            dateRangeDisplay = false
            reportToDisplay = <ReportMerchantBalances showSystemMessage={props.showSystemMessage} />
            break
        case 'bizdev_pipeline':
            reportTitle = 'Bizdev Pipeline'
            useFilters = false
            reportToDisplay = <ReportBizDevPipeline showSystemMessage={props.showSystemMessage} />
            break       
        default:
            break
    }

    document.title = `${reportTitle} Report`

    let filterClasses = `d-flex align-items-center`

    let trendFilters = {
        timeFrame: oldState.timeFrame,
        filterTrend: oldState.filterTrend,
        // mgrId: oldState.mgrId,
        mgrId: 0,
        bizDevMgrId: oldState.bizDevMgrId,
        minT1: oldState.minT1Margin,
        date: oldState.filterTrendDate,
    }

    return (
        <React.Fragment>
            <div className="page-header">
                <span className="mb-h1">Reports</span>
                {/* <HeaderStats showSystemMessage={props.showSystemMessage}></HeaderStats> */}
            </div>
            <div className="card">
                <div className="card-body">
                    <form>
                        <div className={filterClasses}>
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark">{reportTitle}</h1>
                            </div>
                            {useFilters && (
                                <React.Fragment>
                                    {managerDropdownDisplay ?
                                        (oldState.type === 'margin') ?
                                            showMarginSetManager ?
                                                <ManagerDropdown key={mgrType} mgrId={oldState.mgrId} mgrType={mgrType} mgrSelected={handleManagerChange} showSystemMessage={props.showSystemMessage} />
                                                :
                                                <div className="mr-3">{auth.user.name}</div>
                                            :
                                            <ManagerDropdown key={mgrType} mgrId={oldState.mgrId} mgrType={mgrType} mgrSelected={handleManagerChange} showSystemMessage={props.showSystemMessage} />
                                        :
                                        ''
                                    }
                                    {nameFilterDisplay && <NameFilter />}
                                    {dateRangeDisplay && <DateRangeFilter startDate={oldState.startDate} endDate={oldState.endDate} quickDate={oldState.quickDate} selectedStartDate={oldState.selectedStartDate} selectedEndDate={oldState.selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />}
                                    {countryFilterDisplay && <CountryFilter />}
                                    {marginsFilterDisplay && (
                                        <div className="no-border pl-4 pr-4" align="left">
                                            <Checkbox id="payable" name="Payable" checkboxChanged={checkboxChangeHandler} isChecked={oldState.payable} />
                                            <Checkbox id="waiting" name="Waiting" checkboxChanged={checkboxChangeHandler} isChecked={oldState.waiting} />
                                            <Checkbox id="perlead" name="Fixed (per lead)" checkboxChanged={checkboxChangeHandler} isChecked={oldState.perlead} />
                                            <Checkbox id="revshare" name="Percentage (revshare)" checkboxChanged={checkboxChangeHandler} isChecked={oldState.revshare} />
                                        </div>
                                    )}
                                    <p />
                                    {(oldState.type === 'campaignTrend' || oldState.type === 'affiliateTrend') && <CampaignTrendFilters managerId={oldState.mgrId} bizdevManagerId={oldState.bizDevMgrId} mmSelected={(mgrId) => handleManagerChange(mgrId)} bmSelected={(bizMgrId) => handleBizDevManagerChange(bizMgrId)} filtersChanged={(source, value) => handleFilterChange(source, value)} filters={trendFilters} minT1MarginSet={(minMargin) => handleT1MinMarrgin(minMargin)} resetFilters={(e) => handleResetFilters(e)} dateSelected={(date) => handleDateSelect(date)} type={oldState.type} />}
                                    <div>
                                        <button onClick={(e) => handleReport(e)} className="btn btn-sm btn-primary">
                                            Refresh Report
                                        </button>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </form>
                </div>
                {/* {(oldState.type === 'affiliate_earnings' ) &&
                            <Pagination
                                totalPages={oldState.totalPages}
                                currentPage={oldState.page}
                                handlePageChange={handlePageChange} />
                        } */}
                {oldState.dataLoaded &&
                    /* Display the selected report */
                    reportToDisplay}
                {/* {(oldState.type === 'affiliate_earnings' ) &&
                            <div className="mt-2">
                                <Pagination
                                    totalPages={oldState.totalPages}
                                    currentPage={oldState.page}
                                    handlePageChange={handlePageChange} />
                            </div>
                        } */}
            </div>
        </React.Fragment>
    )
}