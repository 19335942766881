import React, { useState, useEffect, useContext } from 'react'
import { Route, Switch, withRouter, useLocation } from 'react-router-dom'

import { useQuery } from './hooks/useQuery'
import { AuthProvider } from './context/AuthContext'

import ScrollToTop from './hoc/ScrollToTop/ScrollToTop'
import Layout from './templates/Layout/Layout'

import JobDashboard from './pages/JobDashboard/JobDashboard'

import SystemMessage from './templates/SystemMessage/SystemMessage'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faCircle, faCog, faPaperPlane, faComment, faMoneyBillWave, faSearch, faEnvelopeSquare, faMobileAlt, faImages, faCrosshairs, faDesktop, faGavel, faCopy, faCalendarAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import './App.scss'

import Login from './pages/Login/Login'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'

import Reports from './pages/Reports/Reports'
import CFLogin from './pages/CFLogin/CFLogin'

import AvatarDashboard from './pages/Avatar/AvatarDashboard/AvatarDashboard'
import AvatarProperty from './pages/Avatar/AvatarProperty/AvatarProperty'
import AvatarPropertyManager from './pages/Avatar/AvatarPropertyManager/AvatarPropertyManager'
import AvatarSite from './pages/Avatar/AvatarSite/AvatarSite'
import AvatarSiteManager from './pages/Avatar/AvatarSiteManager/AvatarSiteManager'
import AvatarSurvey from './pages/Avatar/AvatarSurvey/AvatarSurvey'
import AvatarSurveyManager from './pages/Avatar/AvatarSurveyManager/AvatarSurveyManager'
import AvatarSurveyEventOverrides from './pages/Avatar/AvatarSurvey/AvatarSurveyEventOverrides/AvatarSurveyEventOverrides'
import AvatarReports from './pages/Avatar/AvatarReports/AvatarReports'

import SearchId from './pages/SearchId/SearchId'

import AffiliateSettings from './pages/Affiliate/AffiliateSettings/AffiliateSettings'
import Affiliate from './pages/Affiliate/Affiliate'
import AffiliateLoginHistory from './pages/Affiliate/AffiliateLoginHistory/AffiliateLoginHistory'
import ClickReferrers from './components/ClickReferrers/ClickReferrers'
import ReferrerDetails from './components/ReferrerDetails/ReferrerDetails'

import ResetPassword from './pages/ResetPassword/ResetPassword'
import Dashboard from './pages/Dashboard/Dashboard'
import NoAccess from './pages/NoAccess/NoAccess'
import AuthSettings from './pages/AuthSettings/AuthSettings'
import Vacation from './pages/Vacation/Vacation'

import AccountHistoryForAffiliate from './pages/Affiliate/AccountHistoryForAffiliate/AccountHistoryForAffiliate'
import OTP from './pages/Affiliate/OTP/OTP'
import MerchantApprovalsForAffiliate from './pages/Affiliate/MerchantApprovalsForAffiliate/MerchantApprovalsForAffiliate'
import CampaignCountryOverrides from './components/CampaignCountryOverrides/CampaignCountryOverrides'
import CampaignApprovals from './components/CampaignApprovals/CampaignApprovals'
import DailyCampaignCaps from './components/DailyCampaignCaps/DailyCampaignCaps'
//import LeadReferrers from './pages/Affiliate/LeadReferrers/LeadReferrers'
import LeadReferrers from './components/LeadReferrers/LeadReferrers'
import ClickUserAgents from './pages/Affiliate/ClickUserAgents/ClickUserAgents'
import CallbackHistory from './pages/Affiliate/CallbackHistory/CallbackHistory'
import CampaignRequestsSummary from './components/CampaignRequestsSummary/CampaignRequestsSummary'
import CampaignViews from './components/CampaignViews/CampaignViews'
import Callbacks from './components/Callbacks/Callbacks'
import CampaignSearches from './pages/Affiliate/CampaignSearches/CampaignSearches'
import CreateAffiliateNotification from './pages/Affiliates/CreateAffiliateNotification/CreateAffiliateNotification'
import LeadGeos from './components/LeadGeos/LeadGeos'
import IpDetails from './pages/Affiliate/AffiliateLoginHistory/IpDetails/IpDetails'
import ReportsStats from './pages/ReportsStats/ReportsStats'
import AffiliateResources from './pages/Affiliate/AffiliateResources/AffiliateResources'
import SendPaymentNotices from './pages/Affiliate/SendPaymentNotices/SendPaymentNotices'
import BatchTransferAffiliates from './pages/Affiliate/BatchTransferAffiliates/BatchTransferAffiliates'
import AffiliateHoldPaymentAnalyzer from './pages/Affiliate/AffiliateHoldPaymentAnalyzer/AffiliateHoldPaymentAnalyzer'
import callAPI from './helpers/callAPI'
import Advertiser from './pages/Advertiser/Advertiser'
import CampaignList from './pages/Advertiser/components/CampaignList/CampaignList'
import Campaign from './pages/Campaign/Campaign'
import AdvertiserApplications from './pages/Advertisers/AdvertiserApplications/AdvertiserApplications'
import EditApplication from './pages/Advertisers/AdvertiserApplications/EditApplication/EditApplication'
import AdvertiserList from './pages/Advertisers/AdvertiserList/AdvertiserList'

import AffiliateMailer from './pages/Affiliates/AffiliateMailer/AffiliateMailer'

import LeadUserAgents from './components/LeadUserAgents/LeadUserAgents'
import PaymentDelayOverrides from './components/PaymentDelayOverrides/PaymentDelayOverrides'

import AffiliatePitchReport from './pages/ReportsCustom/AffiliatePitchReport/AffiliatePitchReport'
import CampaignPromiseReport from './pages/ReportsCustom/CampaignPromiseReport/CampaignPromiseReport'
import SearchAffiliates from './pages/Affiliates/SearchAffiliates/SearchAffiliates'
import SearchCampaigns from './pages/Campaigns/SearchCampaigns/SearchCampaigns'
import AdvertiserSettings from './pages/Advertiser/AdvertiserSettings/AdvertiserSettings'
import SearchAdvertisers from './pages/Advertisers/SearchAdvertisers/SearchAdvertisers'
import Search from './pages/Search/Search'
import ServerPostbackCalls from './pages/Campaign/ServerPostbackCalls/ServerPostbackCalls'
import AddLeads from './pages/Campaign/AddLeads/AddLeads'
import AddLeadsAvatar from './pages/Campaign/AddLeadsAvatar/AddLeadsAvatar'
import SpecialRates from './components/SpecialRates/SpecialRates'
import MarketingCampaigns from './pages/Marketing/MarketingCampaigns/MarketingCampaigns'
import MarketingPlatforms from './pages/Marketing/MarketingPlatforms/MarketingPlatforms'
import AdvertiserTrackingPlatforms from './pages/Advertiser/components/AdvertiserTrackingPlatforms/AdvertiserTrackingPlatforms'
import Creatives from './pages/Campaign/Creatives/Creatives'
import CreateMapAccount from './pages/Advertiser/components/CreateMapAccount/CreateMapAccount'
import ScheduleCancellation from './pages/Campaign/ScheduleCancellation/ScheduleCancellation'
import CampaignSettings from './pages/Campaign/CampaignSettings/CampaignSettings'
import AppsVerificationList from './pages/Compliance/AppsVerificationList/AppsVerificationList'
import FraudReportDetailed from './pages/Compliance/FraudReportDetailed/FraudReportDetailed'
import AvatarPropertySetOverrides from './pages/Avatar/AvatarProperty/PropertySetOverrides/PropertySetOverrides'
import LeadReversalTicketManager from './pages/LeadReversalTicketManager/LeadReversalTicketManager'
import AppsRejectedList from './pages/Compliance/AppsRejectedList.js/AppsRejectedList'
import AffiliateFraudCheck from './pages/Compliance/AffiliateFraudCheck/AffiliateFraudCheck'
import ListAdmins from './pages/Admin/ListAdmins/ListAdmins'
import AdvertiserBalanceReport from './pages/Advertisers/AdvertiserBalanceReport/AdvertiserBalanceReport'
import ListCountryRedirects from './pages/Campaigns/ListCountryRedirects/ListCountryRedirects'
import Blacklist from './pages/Compliance/Blacklist/Blacklist'
import AdvertiserInvoiceReport from './pages/Advertisers/AdvertiserInvoiceReport/AdvertiserInvoiceReport'
import AdvertiserInvoiceList from './pages/Advertisers/AdvertiserInvoiceList/AdvertiserInvoiceList'
import FlagTypes from './pages/Admin/FlagTypes/FlagTypes'
import CampaignCategories from './pages/Campaigns/CampaignCategories/CampaignCategories'
import ApprovalQuestions from './pages/Campaigns/ApprovalQuestions/ApprovalQuestions'
import AffiliateWatchlist from './pages/Compliance/AffiliateWatchlist/AffiliateWatchlist'
import AffiliateTips from './pages/Admin/AffiliateTips/AffiliateTips'
import Sites from './pages/Callrouted/Sites/Sites'
import { AffiliateInitialMailings } from './pages/Marketing/AffiliateInitialMailings/AffiliateInitialMailings'
import { HelpText } from './pages/Admin/AffiliateInitialMailings/HelpText'
import MaxMoney from './pages/MaxMoney/MaxMoney'
import MaxMoneyReport from './pages/MaxMoney/Components/MaxMoneyReport'
import MaxMoneyAffs from './pages/MaxMoney/Components/MaxMoneyAffs'
import MaxMoneyModal from './pages/MaxMoney/Components/MaxMoneyModal'
import Jobs from './pages/Admin/Jobs/Jobs'
import JobsModal from './pages/Admin/Jobs/Components/JobsModal'
import ManageDomains from './pages/Admin/ManageDomains/ManageDomains'
import CampaignKeywords from './pages/Campaigns/ListKeywords/ListKeywords'
import ComplianceFollowupReport from './pages/Compliance/ComplianceFollowupReport/ComplianceFollowupReport'
import ChangePassword from './pages/ChangePassword/ChangePassword'
import AffiliateFollowupReport from './pages/Affiliates/AffiliateFollowupReport/AffiliateFollowupReport'
import AdvertiserFollowupReport from './pages/Advertisers/AdvertiserFollowupReport/AdvertiserFollowupReport'
import ModifyLeads from './pages/Compliance/ModifyLeads/ModifyLeads'
import MissingInvoiceInfo from './pages/Advertisers/MissingInvoiceInfo/MissingInvoiceInfo'
import ReversalStatusReport from './pages/Advertisers/ReversalStatusReport/ReversalStatusReport'
import AffiliatePriorityEarnings from './pages/Affiliates/AffiliatePriorityEarnings/AffiliatePriorityEarnings'
import TrafficToday from './pages/Affiliates/TrafficToday/TrafficToday'
import Cancelled from './pages/Affiliates/Cancelled/Cancelled'
import PerformanceBonus from './pages/Affiliates/PerformanceBonus/PerformanceBonus'
import AffiliatesByCountry from './pages/Affiliates/AffiliatesByCountry/AffiliatesByCountry'
import PayoutInstructions from './pages/Admin/PayoutInstructions/PayoutInstructions'
import ReverseLeads from './pages/Compliance/ReverseLeads/ReverseLeads'
import ReportDormantAffiliates from './pages/Affiliates/ReportDormantAffiliates/ReportDormantAffiliates'
import ReportDormantBalances from './pages/Affiliates/ReportDormantBalances/ReportDormantBalances'
import AffiliateTransactionDetails from './pages/Affiliate/TransactionDetails/TransactionDetails'
import NewCampaigns from './pages/Campaigns/NewCampaigns/NewCampaigns'
import ApprovalRequests from './pages/Campaigns/ApprovalRequests/ApprovalRequests'
import ReviewRequestWrapper from './pages/Campaigns/ApprovalRequests/ReviewRequestWrapper/ReviewRequestWrapper'
import ReportTransferredAffiliates from './pages/Affiliates/ReportTransferredAffiliates/ReportTransferredAffiliates'
import Highlighted from './pages/Campaigns/Highlighted/Highlighted'
import ReportIncompleteApps from './pages/Affiliates/ReportIncompleteApps/ReportIncompleteApps'
import TopCampaignsByCountry from './pages/Campaigns/TopCampaignsByCountry/TopCampaignsByCountry'
import AffPerformanceComp from './pages/Affiliates/PerfComp/PerfComp'
import MissingScreenshots from './pages/Campaigns/MissingScreenshots/MissingScreenshots'
import AdvertiserMailer from './pages/Advertisers/AdvertiserMailer/AdvertiserMailer'
import IncompleteApps from './pages/Affiliates/IncompleteApps/IncompleteApps'
import RejectedMarketingApps from './pages/Affiliates/RejectedMarketingApps/RejectedMarketingApps'
import MarketingCampaignReport from './pages/Marketing/MarketingCampaignReport/MarketingCampaignReport'
import AAMPerformanceReport from './pages/Admin/AAMPerformanceReport/AAMPerformanceReport'
import AMPerformanceReport from './pages/Admin/AMPerformanceReport/AMPerformanceReport'
import Affiliates from './pages/Affiliates/Affiliates/Affiliates'
import PageViews from './pages/Admin/PageViews/PageViews'
import ReportTopAffiliates from './pages/Affiliates/ReportTopAffiliates/ReportTopAffiliates'
import Applications from './pages/Affiliates/Applications/Applications'
import ReportTopCampaigns from './pages/Campaigns/ReportTopCampaigns/ReportTopCampaigns'
import BizdevPerformanceReport from './pages/Admin/BizdevPerformanceReport/BizdevPerformanceReport'
import SuppressionReport from './pages/Campaigns/SuppressionReport/SuppressionReport'
import WorkSheetReport from './pages/Campaigns/WorkSheetReport/WorkSheetReport'
import SearchSearches from './pages/Affiliates/SearchSearches/SearchSearches'
import ReversalReport from './pages/ReversalReport/ReversalReport'
import TipaltiMissingDocs from './pages/Affiliates/TipaltiMissingDocs/TipaltiMissingDocs'
import Referrals from './pages/Affiliate/Referrals/Referrals'
import CampaignPitchReport from './pages/ReportsCustom/CampaignPitchReport/CampaignPitchReport'
import KPIReport from './pages/Campaign/KPIReport/KPIReport'
import MobileParameters from './pages/Campaigns/MobileParameters/MobileParameters'
import TwoFASetup from './pages/Admin/TwoFASetup/TwoFASetup'
import APILeadsReview from './pages/Advertisers/ApiLeadsReview/ApiLeadsReview'
import FollowupSummaryReport from './pages/Advertisers/FollowupSummaryReport/FollowupSummaryReport'
import AffiliateFollowupSummaryReport from './pages/Affiliates/FollowupSummaryReport/FollowupSummaryReport'
import LinkFilterOverrides from './components/LinkFilterOverrides/LinkFilterOverrides'
import CommissionReport from './pages/CommissionReport/CommissionReport'
import ReportAffiliateCampaignPickups from './pages/Affiliates/ReportAffiliateCampaignPickups/ReportAffiliateCampaignPickups'
import ReportNewlyRunCampaigns from './pages/Campaigns/ReportNewlyRunCampaigns/ReportNewlyRunCampaigns'
import ReportAffiliateEarningChanges from './pages/Affiliates/ReportAffiliateEarningChanges/ReportAffiliateEarningChanges'
import Followups from './pages/Admin/Followups/Followups'
import FraudPullReport from './pages/Compliance/FraudPullReport/FraudPullReport'
import PhotoId from './pages/Affiliate/PhotoId/PhotoId'
import LifetimeEarningsGraph from './pages/Affiliate/LifetimeEarningsGraph/LifetimeEarningsGraph'
import AvatarScrubOverrides from './components/AvatarScrubOverrides/AvatarScrubOverrides'
import VerifyApplicationWrapper from './pages/Affiliate/VerifyApplicationWrapper/VerifyApplicationWrapper'
import ReviewApplicationWrapper from './pages/Affiliate/ReviewApplicationWrapper/ReviewApplicationWrapper'
import Analytics from './components/Analytics/Analytics'
import ManageOutOfOffice from './pages/Admin/ManageOutOfOffice/ManageOutOfOffice'
import { SystemMessageContext } from './context/SystemMessageContext'
import MerchantCampaignPullsFull from './components/ReviewRequest/MerchantCampaignPulls/MerchantCampaignPullsFull/MerchantCampaignPullsFull'
import MerchantCampaignPullsWrapper from './components/ReviewRequest/MerchantCampaignPulls/MerchantCampaignPullsWrapper/MerchantCampaignPullsWrapper'
import ReportStaffDirectory from './pages/Reports/ReportStaffDirectory/ReportStaffDirectory'

//fab imports all of the brand icons. You use it differently than other icons. example: icon={['fab','apple']}
library.add(faSpinner, fab, faCircle, faCog, faPaperPlane, faComment, faMoneyBillWave, faSearch, faEnvelopeSquare, faMobileAlt, faImages, faCrosshairs, faDesktop, faGavel, faCopy, faCalendarAlt, faPencilAlt)

const App = (props) => {
    const [administrator, setAdministrator] = useState({
        id: '',
        name: '',
    })
    const [permissions, setPermissions] = useState({})

    const { showSystemMessage, hideSystemMessage, systemMessage, setSystemMessage } = useContext(SystemMessageContext)


    const [authenticated, setAuthenticated] = useState(localStorage.getItem('adm-auth-token') ? true : false)
    const [authorized, setAuthorized] = useState(false)

    const location = useLocation()
    const query = useQuery()

    useEffect(async () => {
        await checkAuthentication()
        await getAdmin()
    }, [authenticated, location.pathname, location.search])

    const checkAuthentication = async () => {
        // Make a call to API /application/verifyToken -> Simple endpoint with auth middleware that returns {success:true} if success
        const mbToken = localStorage.getItem('adm-auth-token')

        if (mbToken) {
            let tokenResult = await callAPI('application/verifyToken', 'GET')

            if (tokenResult.success) {
                setAuthenticated(true)
            } else {
                logout()
            }
        } else {
            logout()
        }
    }

    const getAdmin = async () => {
        const mbToken = localStorage.getItem('adm-auth-token')
        if (mbToken) {
            let result = await callAPI('admin', 'GET')

            if (result.success) {
                setAdministrator({ id: result.user.id, name: result.user.name, role: result.user.role, email: result.user.email })
                setPermissions(result.permissions)
                callAPI(`admin/${result.user.id}/pageView`, 'POST', { screen_name: location.pathname, query_string: location.search })
            }

            setAuthorized(true)
        }
    }

    const logout = () => {
        // Remove adm-auth-token from localStorage, setAuthenticated(false)
        const adminToken = !localStorage.getItem('adm-auth-token') ? '' : localStorage.getItem('adm-auth-token')

        if (adminToken !== '') {
            localStorage.removeItem('adm-auth-token')
            showSystemMessage('error', 'You have been logged out.')
        }

        setAuthenticated(false)
    }

    const logoutFromAllDevices = async () => {
        // CURRENTLY NOT USED!!
        // Remove adm-auth-token from localStorage, setAuthenticated(false)
        const adminToken = !localStorage.getItem('adm-auth-token') ? '' : localStorage.getItem('adm-auth-token')

        if (adminToken !== '') {
            // Call forcelogout
            await callAPI('admin/logoutFromAllDevices', 'POST', {}, false)

            localStorage.removeItem('adm-auth-token')
            showSystemMessage('error', 'You have been logged out.')
        }

        setAuthenticated(false)
    }

    return (
        <ScrollToTop>
            <div className="App">


                <SystemMessage />
                {authenticated ? (
                    authorized && (
                        <AuthProvider administrator={administrator} permissions={permissions}>
                            <Layout showSystemMessage={showSystemMessage} logout={logout} logoutFromAllDevices={logoutFromAllDevices}>
                                <Switch>
                                    {/* Adding a question mark after the param means it's optional. The component will still mount without it */}
                                    <Route path="/reports/stats/:type" render={(props) => <ReportsStats key={`${props.match.params.type}/${props.location.search}`} type={props.match.params.type} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/reports/custom/campaignPromiseReport" render={(props) => <CampaignPromiseReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/reports/custom/campaignPitchReport" render={(props) => <CampaignPitchReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/reports/reversals/:type" render={(props) => <ReversalReport key={`${props.match.params.type}/${props.location.search}`} type={props.match.params.type} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/reports/:type?" render={(props) => <Reports key={`${props.match.params.type}/${props.location.search}`} type={props.match.params.type} location={props.location} history={props.history} showSystemMessage={showSystemMessage} />} />

                                    {/* AFFILIATE */}

                                    <Route path="/affiliate/:affiliateId/transactionDetails" render={(props) => <AffiliateTransactionDetails key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} transactionId={query.get('transactionId')} />} />
                                    <Route path="/affiliate/:affiliateId/loginHistory" render={(props) => <AffiliateLoginHistory key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/edit" render={(props) => <AffiliateSettings key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/clickReferrers" render={(props) => <ClickReferrers key={`${props.match.params.affiliateId}-${[props.location.search]}`} affiliateIdParam={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/campaignApprovals" render={(props) => <CampaignApprovals key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/filterOverrides" render={(props) => <LinkFilterOverrides key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/campaignCaps" render={(props) => <DailyCampaignCaps key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/leadReferrers" render={(props) => <LeadReferrers key={props.match.params.affiliateId} affiliateIdParam={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/ReferrerDetails" render={(props) => <ReferrerDetails key={props.match.params.affiliateId} affiliateIdParam={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/clickUserAgents" render={(props) => <ClickUserAgents key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/callbackHistory" render={(props) => <CallbackHistory key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/accountHistory" render={(props) => <AccountHistoryForAffiliate affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/maxmoney/accountHistory" render={(props) => <AccountHistoryForAffiliate affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} maxMoney />} />
                                    <Route path="/affiliate/:affiliateId/OTPTokens" render={(props) => <OTP key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/paymentDelayOverrides" render={(props) => <PaymentDelayOverrides key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/advertiserApprovals" render={(props) => <MerchantApprovalsForAffiliate key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/campaignCountryOverrides" render={(props) => <CampaignCountryOverrides key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/campaignRequests" render={(props) => <CampaignRequestsSummary key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/campaignViews" render={(props) => <CampaignViews key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/specialRates" render={(props) => <SpecialRates key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/avatarScrubOverrides" render={(props) => <AvatarScrubOverrides key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/callbacks" render={(props) => <Callbacks affiliateId={props.match.params.affiliateId} key={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/leadUserAgents" render={(props) => <LeadUserAgents affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/campaignSearches" render={(props) => <CampaignSearches key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/leadLocations" render={(props) => <LeadGeos key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/fraudcheck" render={(props) => <AffiliateFraudCheck key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/referrals" render={(props) => <Referrals key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/photoId/:side" render={(props) => <PhotoId key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} side={props.match.params.side} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/lifetimeEarnings" render={(props) => <LifetimeEarningsGraph key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/application/verify" render={(props) => <VerifyApplicationWrapper key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/application/review" render={(props) => <ReviewApplicationWrapper key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/analytics" render={(props) => <Analytics key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId/advertiserCampaignPulls" render={(props) => <MerchantCampaignPullsWrapper key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliate/:affiliateId" render={(props) => <Affiliate key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />

                                    {/* AFFILIATES */}

                                    <Route path="/affiliates/rejectedmarketingapps" render={(props) => <RejectedMarketingApps key={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/mailer" render={(props) => <AffiliateMailer key="affiliateMailer" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/topByCountry" render={(props) => <AffiliatesByCountry key={props.match.params.affiliateId} showSystemMessage={showSystemMessage} defaultCountry={query.get('country')} />} />
                                    <Route path="/affiliates/performanceBonus" render={(props) => <PerformanceBonus key={props.match.params.affiliateId} showSystemMessage={showSystemMessage} mgrId={query.get('mgrId')} />} />
                                    <Route path="/affiliates/cancelled" render={(props) => <Cancelled key={props.match.params.affiliateId} showSystemMessage={showSystemMessage} mgrId={query.get('mgrId')} />} />
                                    <Route path="/affiliates/trafficToday" render={(props) => <TrafficToday key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/marginByPriority" render={(props) => <AffiliatePriorityEarnings key={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/affiliateNews" render={(props) => <CreateAffiliateNotification key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/search" render={(props) => <SearchAffiliates key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/paymentNotices" render={(props) => <SendPaymentNotices key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/affiliateNews" render={(props) => <CreateAffiliateNotification key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId || 0} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/resources" render={(props) => <AffiliateResources key={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/batchTransfer" render={(props) => <BatchTransferAffiliates key={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/followups" render={(props) => <AffiliateFollowupReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/pitchReport" render={(props) => <AffiliatePitchReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/dormant/balances" render={(props) => <ReportDormantBalances showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/dormant" render={(props) => <ReportDormantAffiliates key={`${props.location.search}`} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/transferred" render={(props) => <ReportTransferredAffiliates key={`${props.location.search}`} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/incompleteApps" render={(props) => <IncompleteApps key={props.match.params.affiliateId} showSystemMessage={showSystemMessage} defaultFilter={query.get('filter')} />} />
                                    <Route path="/affiliates/reportIncompleteApps" render={(props) => <ReportIncompleteApps showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/performanceComparison" render={(props) => <AffPerformanceComp showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates/top" render={(props) => <ReportTopAffiliates key={props.match.params.adminId} showSystemMessage={showSystemMessage} adminId={query.get('adminId')} />} />
                                    <Route path="/affiliates/applications" render={(props) => <Applications key={props.match.params.adminId} showSystemMessage={showSystemMessage} adminId={query.get('adminId')} />} />
                                    <Route path="/affiliates/searches" render={(props) => <SearchSearches key={props.match.params.adminId} showSystemMessage={showSystemMessage} adminId={query.get('adminId')} />} />
                                    <Route path="/affiliates/campaignPickups" render={(props) => <ReportAffiliateCampaignPickups key={props.match.params.adminId} showSystemMessage={showSystemMessage} adminId={query.get('adminId')} />} />
                                    <Route path="/affiliates/earningChanges" render={(props) => <ReportAffiliateEarningChanges key={props.match.params.adminId} showSystemMessage={showSystemMessage} adminId={query.get('adminId')} />} />
                                    <Route path="/affiliates/followupSummaryReport" render={(props) => <AffiliateFollowupSummaryReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/affiliates" render={(props) => <Affiliates key={props.match.params.affiliateId} showSystemMessage={showSystemMessage} defaultStatus={query.get('status')} defaultAdminId={query.get('adminId')} />} />

                                    {/*ADMIN*/}
                                    <Route path="/admin/tickets/manage" render={(props) => <LeadReversalTicketManager showSystemMessage={showSystemMessage} location={props.location} history={props.history} />} />
                                    <Route path="/admin/managers" render={(props) => <ListAdmins key={props.match.params.advertiserId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/admin/flags" render={(props) => <FlagTypes showSystemMessage={showSystemMessage} />} />
                                    <Route path="/admin/affiliateTips" render={(props) => <AffiliateTips showSystemMessage={showSystemMessage} />} />
                                    <Route path="/admin/domains" render={(props) => <ManageDomains key={props.match.params.advertiserId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/admin/job/create" render={(props) => <JobsModal key={props.match.params.advertiserId} showSystemMessage={showSystemMessage} isCreate jobId={53} />} />
                                    <Route path="/admin/job/:jobId" render={(props) => <JobsModal key={props.match.params.advertiserId} showSystemMessage={showSystemMessage} jobId={props.match.params.jobId} />} />
                                    <Route path="/admin/jobs" render={(props) => <Jobs key={props.match.params.advertiserId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/admin/helpText" render={(props) => <HelpText key={props.match.params.advertiserId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/admin/payoutInstructions" render={(props) => <PayoutInstructions showSystemMessage={showSystemMessage} />} />
                                    <Route path="/admin/AMPerformanceReport" render={(props) => <AMPerformanceReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/admin/AAMPerformanceReport" render={(props) => <AAMPerformanceReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/admin/:adminId/pageViews" render={(props) => <PageViews key={props.match.params.advertiserId} showSystemMessage={showSystemMessage} defaultAdminId={props.match.params.adminId} />} />
                                    <Route path="/admin/:adminId/BizdevPerformanceReport" render={(props) => <BizdevPerformanceReport key={props.match.params.advertiserId} showSystemMessage={showSystemMessage} defaultAdminId={props.match.params.adminId} />} />
                                    <Route path="/admin/:adminId/followups" render={(props) => <Followups key={props.match.params.adminId} showSystemMessage={showSystemMessage} defaultAdminId={props.match.params.adminId} />} />
                                    <Route path="/admin/twofasetup" render={(props) => <TwoFASetup key={props.match.params.advertiserId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/admin/outofoffice" render={(props) => <ManageOutOfOffice showSystemMessage={showSystemMessage} />} />
                                    <Route path="/admin/staffDirectory" render={(props) => <ReportStaffDirectory showSystemMessage={showSystemMessage} />} />
                                    {/* ADVERTISER */}

                                    <Route path="/advertiser/:advertiserId/createMapAccount" render={(props) => <CreateMapAccount key={props.match.params.advertiserId} advertiserId={props.match.params.advertiserId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertiser/:advertiserId/campaigns" render={(props) => <CampaignList key={props.match.params.advertiserId} advertiserId={props.match.params.advertiserId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertiser/:advertiserId/edit" render={(props) => <AdvertiserSettings key={props.match.params.advertiserId} advertiserId={props.match.params.advertiserId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertiser/:advertiserId" render={(props) => <Advertiser key={props.match.params.advertiserId} advertiserId={props.match.params.advertiserId} showSystemMessage={showSystemMessage} />} />

                                    {/* CAMPAIGN */}
                                    <Route path="/campaign/:campaignId/creatives" render={(props) => <Creatives key={props.match.params.advertiserId} campaignId={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/clickReferrers" render={(props) => <ClickReferrers key={`${props.match.params.affiliateId}-${[props.location.search]}`} campaignIdParam={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/leadReferrers" render={(props) => <LeadReferrers key={props.match.params.affiliateId} campaignIdParam={props.match.params.campaignId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/ReferrerDetails" render={(props) => <ReferrerDetails key={props.match.params.campaignId} campaignIdParam={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/leadUserAgents" render={(props) => <LeadUserAgents campaignId={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/analytics" render={(props) => <Analytics key={props.match.params.advertiserId} campaignId={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/campaignApprovals" render={(props) => <CampaignApprovals key={props.match.params.campaignId} campaignId={props.match.params.campaignId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/filterOverrides" render={(props) => <LinkFilterOverrides key={props.match.params.campaignId} campaignId={props.match.params.campaignId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/campaignCaps" render={(props) => <DailyCampaignCaps key={props.match.params.campaignId} campaignId={props.match.params.campaignId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/paymentDelayOverrides" render={(props) => <PaymentDelayOverrides key={props.match.params.campaignId} campaignId={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/campaignRequests" render={(props) => <CampaignRequestsSummary key={props.match.params.campaignId} campaignId={props.match.params.campaignId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/campaignViews" render={(props) => <CampaignViews key={props.match.params.campaignId} campaignId={props.match.params.campaignId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/leadLocations" render={(props) => <LeadGeos key={props.match.params.campaignId} campaignId={props.match.params.campaignId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/serverPostbackCalls" render={(props) => <ServerPostbackCalls key={props.match.params.campaignId} campaignId={props.match.params.campaignId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/addLeads" render={(props) => <AddLeads key={props.match.params.campaignId} campaignId={props.match.params.campaignId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/addLeadsAvatar" render={(props) => <AddLeadsAvatar key={props.match.params.campaignId} campaignId={props.match.params.campaignId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/specialRates" render={(props) => <SpecialRates key={props.match.params.campaignId} campaignId={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/avatarScrubOverrides" render={(props) => <AvatarScrubOverrides key={props.match.params.campaignId} campaignId={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/campaignCountryOverrides" render={(props) => <CampaignCountryOverrides key={props.match.params.campaignId} campaignId={props.match.params.campaignId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/callbacks" render={(props) => <Callbacks campaignId={props.match.params.campaignId} key={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/settings" render={(props) => <CampaignSettings key={props.match.params.campaignId} campaignId={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/leadUserAgents" render={(props) => <LeadUserAgents campaignId={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId/KPIReport" render={(props) => <KPIReport key={props.match.params.campaignId} campaignId={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaign/:campaignId" render={(props) => <Campaign key={props.match.params.campaignId} campaignId={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />

                                    <Route path="/campaigns/missingScreenshots" render={(props) => <MissingScreenshots showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaigns/countryRedirectSets" render={(props) => <ListCountryRedirects showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaigns/keywords" render={(props) => <CampaignKeywords showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaigns/search" render={(props) => <SearchCampaigns showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaigns/categories" render={(props) => <CampaignCategories showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaigns/approvalQuestions" render={(props) => <ApprovalQuestions showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaigns/approvalRequests/:id" render={(props) => <ReviewRequestWrapper showSystemMessage={showSystemMessage} requestId={props.match.params.id} />} affiliateId={props.match.params.aid} campaignId={props.match.params.oid} />
                                    <Route path="/campaigns/approvalRequests" render={(props) => <ApprovalRequests showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaigns/newCampaigns" render={(props) => <NewCampaigns showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaigns/topCampaignsByCountry" render={(props) => <TopCampaignsByCountry showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaigns/highlighted" render={(props) => <Highlighted showSystemMessage={showSystemMessage} key={props.location.search} />} />
                                    <Route path="/campaigns/suppressionReport" render={(props) => <SuppressionReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaigns/worksheetReport" render={(props) => <WorkSheetReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaigns/mobileParameters" render={(props) => <MobileParameters showSystemMessage={showSystemMessage} />} />
                                    <Route path="/campaigns/newlyRun" render={(props) => <ReportNewlyRunCampaigns key={props.match.params.adminId} showSystemMessage={showSystemMessage} adminId={query.get('adminId')} />} />

                                    {/* ADVERTISERS */}
                                    <Route path="/advertisers/mailer" render={(props) => <AdvertiserMailer key="advertiserMailer" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers/apileadsreview" render={(props) => <APILeadsReview showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers/reversalStatusReport" render={(props) => <ReversalStatusReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers/missingInvoiceInfo" render={(props) => <MissingInvoiceInfo showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers/search" render={(props) => <SearchAdvertisers showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers/applications/:id" render={(props) => <EditApplication key={props.match.params.id} applicationId={props.match.params.id} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers/applications" render={(props) => <AdvertiserApplications key={`${props.location.search}`} id={props.match.params.advertiserId} location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers/platforms/:id" render={(props) => <AdvertiserTrackingPlatforms showSystemMessage={showSystemMessage} platformId={props.match.params.id} />} />
                                    <Route path="/advertisers/platforms" render={(props) => <AdvertiserTrackingPlatforms showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers/balances" render={(props) => <AdvertiserBalanceReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers/followups" render={(props) => <AdvertiserFollowupReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers/invoices/report" render={(props) => <AdvertiserInvoiceReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers/invoices/list" render={(props) => <AdvertiserInvoiceList showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers/followupSummaryReport" render={(props) => <FollowupSummaryReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/advertisers" render={(props) => <AdvertiserList key={props.match.params.advertiserId} advertiserId={props.match.params.advertiserId} showSystemMessage={showSystemMessage} />} />

                                    {/* MARKETING */}
                                    <Route path="/marketing/campaigns" render={(props) => <MarketingCampaigns showSystemMessage={showSystemMessage} />} />
                                    <Route path="/marketing/campaign/:campaignId/report" render={(props) => <MarketingCampaignReport marketingCampaignId={props.match.params.campaignId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/marketing/platforms" render={(props) => <MarketingPlatforms showSystemMessage={showSystemMessage} />} />
                                    <Route path="/marketing/affiliates/mailers" render={(props) => <AffiliateInitialMailings showSystemMessage={showSystemMessage} />} />

                                    {/* AVATAR */}

                                    <Route path="/avatar/dashboard" render={(props) => <AvatarDashboard location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/avatar/propertyManager" render={(props) => <AvatarPropertyManager location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/avatar/property/:propertyId/overrides" render={(props) => <AvatarPropertySetOverrides location={props.location} key={props.match.params.propertyId} propertyId={props.match.params.propertyId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/avatar/property/:propertyId" render={(props) => <AvatarProperty location={props.location} key={props.match.params.propertyId} propertyId={props.match.params.propertyId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/avatar/siteManager" render={(props) => <AvatarSiteManager location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/avatar/site/:siteId" render={(props) => <AvatarSite location={props.location} key={`site-${props.match.params.siteId}`} siteId={props.match.params.siteId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/avatar/survey/:surveyId/eventOverrides" render={(props) => <AvatarSurveyEventOverrides location={props.location} key={`${props.match.params.surveyId}`} surveyId={props.match.params.surveyId} history={props.history} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/avatar/survey/:surveyId" render={(props) => <AvatarSurvey location={props.location} key={`survey-${props.match.params.surveyId}`} surveyId={props.match.params.surveyId} history={props.history} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/avatar/surveyManager" render={(props) => <AvatarSurveyManager location={props.location} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/avatar/reports/:type?" render={(props) => <AvatarReports key={`${props.match.params.type}/${props.location.search}`} type={props.match.params.type} location={props.location} history={props.history} showSystemMessage={showSystemMessage} />} />

                                    {/* Callrouted */}

                                    <Route path="/callrouted/sites" render={() => <Sites showSystemMessage={showSystemMessage} />} />

                                    {/* COMPLIANCE */}

                                    <Route path="/compliance/modifyleads" render={(props) => <ModifyLeads showSystemMessage={showSystemMessage} />} />
                                    <Route path="/compliance/followups" render={(props) => <ComplianceFollowupReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/compliance/blacklist" render={(props) => <Blacklist key={props.location.search} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/compliance/applications/rejected" render={(props) => <AppsRejectedList key={props.location.search} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/compliance/applications" render={() => <AppsVerificationList showSystemMessage={showSystemMessage} />} />
                                    <Route path="/compliance/fraudReport" render={() => <FraudReportDetailed showSystemMessage={showSystemMessage} />} />
                                    <Route path="/compliance/fraudPullReport" render={() => <FraudPullReport showSystemMessage={showSystemMessage} />} />
                                    <Route path="/compliance/useragentWatchlist" render={() => <AffiliateWatchlist showSystemMessage={showSystemMessage} />} />
                                    <Route path="/compliance/reverseLeads" render={() => <ReverseLeads showSystemMessage={showSystemMessage} />} />
                                    <Route path="/compliance/tipaltiMissingDocs" render={(props) => <TipaltiMissingDocs key={props.match.params.adminId} showSystemMessage={showSystemMessage} />} />

                                    {/* TOOLS/ADMIN */}

                                    <Route path="/search" render={(props) => <Search key={props.location.search} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/searchId" render={(props) => <SearchId key={props.location.search} location={props.location} history={props.history} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/authSettings" render={(props) => <AuthSettings key={props.location.search} location={props.location} history={props.history} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/vacation" render={(props) => <Vacation showSystemMessage={showSystemMessage} />} />
                                    <Route path="/ipDetails" render={(props) => <IpDetails key={props.location.search} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/holdPaymentAnalyzer" render={(props) => <AffiliateHoldPaymentAnalyzer key={props.location.search} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/jobDashboard" render={(props) => <JobDashboard location={props.location} auth={true} />} />
                                    <Route path="/user/:adminId/changePassword" render={(props) => <ChangePassword showSystemMessage={showSystemMessage} />} />
                                    <Route path="/commissionReport" render={(props) => <CommissionReport showSystemMessage={showSystemMessage} />} />

                                    {/* MAXMONEY */}

                                    <Route path="/maxmoney/:promoId/affiliates" render={(props) => <MaxMoneyAffs showSystemMessage={showSystemMessage} promoId={props.match.params.promoId} />} />
                                    <Route path="/maxmoney/:promoId/report" render={(props) => <MaxMoneyReport key={props.match.params.promoId} promoId={props.match.params.promoId} showSystemMessage={showSystemMessage} mgrId={query.get('mgrid')} mgrName={query.get('mgrname')} />} />
                                    <Route path="/maxmoney/:promoId/edit" render={(props) => <MaxMoneyModal key={props.match.params.promoId} promoId={props.match.params.promoId} showSystemMessage={showSystemMessage} />} />
                                    <Route path="/maxmoney/create" render={(props) => <MaxMoneyModal showSystemMessage={showSystemMessage} isCreate />} />
                                    <Route path="/maxmoney" render={(props) => <MaxMoney key={props.match.params.affiliateId} affiliateId={props.match.params.affiliateId} showSystemMessage={showSystemMessage} />} />

                                    {/* DASHBOARD */}

                                    <Route path="/dashboard/affiliateDirector" render={() => <Dashboard key="AFFILIATE_DIRECTOR" role="AFFILIATE_DIRECTOR" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/seniorAM" render={() => <Dashboard key="AM_SENIOR" role="AM_SENIOR" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/juniorAM" render={() => <Dashboard key="AM_JUNIOR" role="AM_JUNIOR" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/juniorAMTeamLead" render={() => <Dashboard key="AM_JR_TEAMLEAD" role="AM_JR_TEAMLEAD" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/advertiserMgr" render={() => <Dashboard key="MERMGR" role="MERMGR" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/accounting" render={() => <Dashboard key="ACCOUNTING" role="ACCOUNTING" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/advertiserDirector" render={() => <Dashboard key="ADVERTISER_DIRECTOR" role="ADVERTISER_DIRECTOR" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/compliance" render={() => <Dashboard key="COMPLIANCE" role="COMPLIANCE" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/complianceDirector" render={() => <Dashboard key="COMPLIANCE_DIRECTOR" role="COMPLIANCE_DIRECTOR" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/adopsTeamLead" render={() => <Dashboard key="ADOPS_TEAMLEAD" role="ADOPS_TEAMLEAD" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/adops" render={() => <Dashboard key="ADOPS_MANAGER" role="ADOPS_MANAGER" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/bizdev" render={() => <Dashboard key="BIZDEV" role="BIZDEV" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/marketing" render={() => <Dashboard key="MARKETING_MANAGER" role="MARKETING_MANAGER" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/administrator" render={() => <Dashboard key="ADMINISTRATOR" role="ADMINISTRATOR" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/CEO" render={() => <Dashboard key="CEO" role="CEO" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/CTO" render={() => <Dashboard key="CTO" role="CTO" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/owner" render={() => <Dashboard key="OWNER" role="OWNER" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/accounting" render={() => <Dashboard key="ACCOUNTING" role="ACCOUNTING" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/dashboard/it" render={() => <Dashboard key="IT" role="IT" showSystemMessage={showSystemMessage} />} />
                                    <Route path="/noAccess" render={() => <NoAccess />} />
                                    <Route path="/" render={() => <Dashboard showSystemMessage={showSystemMessage} />} />
                                </Switch>
                            </Layout>
                        </AuthProvider>
                    )
                ) : (
                    <Switch>
                        <Route path="/cflogin" render={(props) => <CFLogin location={props.location} checkAuthentication={checkAuthentication} getAdmin={getAdmin} />} />
                        <Route path="/jobDashboard" render={(props) => <JobDashboard location={props.location} auth={false} />} />
                        <Route path="/forgotPassword" render={(props) => <ForgotPassword location={props.location} showSystemMessage={showSystemMessage} />} />
                        <Route path="/resetPassword" render={(props) => <ResetPassword token={query.get('token')} showSystemMessage={showSystemMessage} />} />
                        <Route path="/" render={(props) => <Login location={props.location} checkAuthentication={checkAuthentication} getAdmin={getAdmin} showSystemMessage={showSystemMessage} />} />
                    </Switch>
                )}
            </div>
        </ScrollToTop>
    )
}

export default withRouter(App)

// 42BruC!Jc@4m
