import { Form, Modal, Button, Table } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import callAPI from '../../../../../helpers/callAPI'

const LPModal = ({ isOpen, setIsOpen, landingPages, refetch, selectedLP, isCreate, showSystemMessage, campaignId }) => {
    const [formData, setFormData] = useState(selectedLP)

    useEffect(() => {
        setFormData(selectedLP)
    }, [selectedLP])

    const validate = (e) => {

        if (isCreate) {
            landingPages.forEach((page) => {

                if (page.lp_name.trim() === e.target.name.value.trim()) {
                    e.target.name.setCustomValidity('Landing Page Name already exists')
                    return false
                }
            })
        } else {
            landingPages.forEach((page) => {
                if (page.lp_record_id !== selectedLP.lp_record_id && page.lp_name.trim() === selectedLP.lp_name.trim()) {
                    e.target.name.setCustomValidity('Landing Page Name already exists')
                    return false
                }
            })
        }

        let landingUrl = e.target.landing_url.value

        if (landingUrl.substring(0, 7) !== 'http://' && landingUrl.substring(0, 8) !== 'https://') {
            e.target.landing_url.setCustomValidity('Landing URL is improperly formatted')
            return false
        }

        return true
    }

    const createLandingPage = async (e) => {
        e.preventDefault()

        let validated = validate(e)

        if (!validated) {
            e.target.reportValidity()
            return false
        }

        const body = {
            lp_default_flag: e.target.default_landing_flag.checked ? 'Y' : 'N',
            lp_landing_url: e.target.landing_url.value,
            lp_name: e.target.name.value,
            lp_record_id: selectedLP.lp_record_id,
            lp_status: e.target.lp_status.value
        }

        const response = await callAPI(`campaign/${campaignId}/landingPages`, 'POST', body)

        if (response.success) {
            refetch()
            setIsOpen(false)
            showSystemMessage('success', 'Landing Page Created')
        } else {
            showSystemMessage('error', response.errors.message)

        }
    }

    const updateLandingPage = async (e) => {
        e.preventDefault()

        let validated = validate(e)

        if (!validated) {
            e.target.reportValidity()
            return false
        }

        const body = {
            lp_default_flag: e.target.default_landing_flag.checked ? 'Y' : 'N',
            lp_landing_url: e.target.landing_url.value,
            lp_name: e.target.name.value,
            lp_record_id: selectedLP.lp_record_id,
            lp_status: e.target.lp_status.value
        }

        const response = await callAPI(`campaign/${campaignId}/landingPages/${selectedLP.lp_record_id}`, 'PUT', body)

        if (response.success) {
            refetch()
            setIsOpen(false)
            showSystemMessage('success', 'Landing Page Updated')
        } else {
            showSystemMessage('error', response.errors.message)
        }
    }

    const inputChangeHandler = (e) => {
        // e.preventDefault()
        e.target.setCustomValidity('')
    }

    const activeLandingPages = landingPages.filter(lp => lp.lp_status === 'A')


    let defaultOptionDisableStatus = false

    if (landingPages.length === 0 || formData.lp_default_flag === 'Y' || formData.lp_status !== 'A') {
        defaultOptionDisableStatus = true
    }

    return (
        <Modal
            show={isOpen}
            onHide={() => {
                setIsOpen(false)
            }}
        >
            <form onSubmit={isCreate ? createLandingPage : updateLandingPage}>
                <Modal.Header closeButton>
                    <Modal.Title>{isCreate ? 'Add Landing Page' : 'Edit Landing Page'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className='table table-borderless'>
                        <tbody>
                            <tr>
                                <td>Landing Page Name</td>
                                <td>
                                    {formData.lp_name}
                                    <Form.Control
                                        type={isCreate ? "text" : "hidden"}
                                        name="name"
                                        placeholder="Enter Landing Page Name"
                                        defaultValue={formData.lp_name}
                                        onChange={inputChangeHandler}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Landing Page URL</td>
                                <td>
                                    <textarea className="form-control" onChange={inputChangeHandler} required name="landing_url">
                                        {formData.lp_landing_url}
                                    </textarea>                                    
                                </td>
                            </tr>
                            <tr>
                                <td>Default Landing Page</td>
                                <td><Form.Check
                                    type="checkbox"
                                    name="default_landing_flag"
                                    defaultChecked={landingPages.length === 0 ? true : (formData.lp_default_flag === 'Y' ? true : false)}
                                    onChange={inputChangeHandler}
                                    // disabled={
                                    //     (landingPages.length === 0 || (formData.lp_status && formData.lp_status !== 'A') || (landingPages.length === 1 && formData.lp_status)) ?
                                    //         true
                                    //         :
                                    //         false
                                    // }
                                    disabled={defaultOptionDisableStatus}
                                />
                                </td>
                            </tr>
                            <tr>
                                <td>Landing Page Status</td>
                                {landingPages.length === 0 || (activeLandingPages.length <= 1 && formData.lp_status === 'A') || formData.lp_default_flag === 'Y' ? <td><span>Active</span>
                                    <input type="hidden" name="lp_status" value="A" />
                                </td> :
                                    <td><Form.Control
                                        as="select"
                                        name="lp_status"
                                        defaultValue={formData.lp_status}
                                        onChange={inputChangeHandler}
                                        required>
                                        <option value="A">Active</option>
                                        <option value="T">Testing</option>
                                        <option value="C">Cancelled</option>
                                    </Form.Control></td>}
                            </tr>
                        </tbody>
                    </table>
                    <h3 className="mt-4">Available Macros:</h3>
                    <Table striped bordered hover size="sm">
                        <tbody>
                            <tr align="left" width="100%">
                                <td>Click ID</td>
                                <td>#ACID#</td>
                            </tr>
                            <tr align="left" width="100%">
                                <td>Affiliate ID</td>
                                <td>#MBID#</td>
                            </tr>
                            <tr align="left" width="100%">
                                <td>IP Address</td>
                                <td>#IP#</td>
                            </tr>
                            <tr align="left" width="100%">
                                <td>Referring URL</td>
                                <td>#REFURL#</td>
                            </tr>
                            <tr align="left" width="100%">
                                <td>SUB-ID 1</td>
                                <td>#S1#</td>
                            </tr>
                            <tr align="left" width="100%">
                                <td>SUB-ID 2</td>
                                <td>#S2#</td>
                            </tr>
                            <tr align="left" width="100%">
                                <td>SUB-ID 3</td>
                                <td>#S3#</td>
                            </tr>
                            <tr align="left" width="100%">
                                <td>SUB-ID 4</td>
                                <td>#S4#</td>
                            </tr>
                            <tr align="left" width="100%">
                                <td>SUB-ID 5</td>
                                <td>#S5#</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    {isCreate ? (
                        <Button type="submit" variant="primary" size="sm">
                            Create
                        </Button>
                    ) : (
                        <Button type="submit" variant="primary" size="sm">
                            Update
                        </Button>
                    )}
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default LPModal

