import { useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'

const BatchUploadBanners = ({ data, showSystemMessage, isOpen, setIsOpen, campaignId, refetch }) => {
    const [formData, setFormData] = useState({
        creative_type: 'B',
        status: 'A',
        landing_url: undefined,
        upload_files: [],
        html_text: undefined,
        creative_id: undefined,
        preview: null,
    })
    const batchCreate = async () => {
        const tempFormData = new FormData()        
        for (let i = 0; i < Object.values(formData).length; i++) {
            tempFormData.append(`upload_files[${i}]`, formData.upload_files[i])
        }
        tempFormData.append('creative_type', formData.creative_type)
        tempFormData.append('status', formData.status)
        tempFormData.append('landing_url', formData.landing_url)
        tempFormData.append('html_text', formData.html_text)
        tempFormData.append('merchant_id', data.advertiser.id)
        const response = await callAPI(`campaign/${campaignId}/batchcreatives`, 'POST', tempFormData, true)
        if (response.success) {
            setIsOpen(false)
            refetch()
            showSystemMessage('success', 'Creative created successfully')
        } else {
            showSystemMessage('error', response.errors.message)
        }
    }

    return (
        <Modal
            show={isOpen}
            onHide={() => {
                setIsOpen(false)
                refetch()
            }}
        >
            <Modal.Header closeButton>Batch Upload Banners</Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <tbody>
                        <tr>
                            <td>Upload File:</td>
                            <td>
                                <Form.Control
                                    type="file"
                                    multiple
                                    onChange={(e) => {
                                        setFormData({ ...formData, upload_files: e.target.files })
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={batchCreate}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default BatchUploadBanners
