import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import moment from 'moment'
import { Triangle } from 'react-feather'

import TableContainer from '../TableContainer'
import Loading from '../../../templates/UI/Loading/Loading'
import Dollars from '../../../templates/Dollars/Dollars'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Campaign from '../../../templates/Campaign/Campaign'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ReportCampaignTrends = ({ type, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])
    const [lastUpdated, setLastUpdated] = useState('')

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const history = useHistory()

    const urlParams = history.location.search ? queryString.parse(history.location.search) : ''

    let timeFrame = urlParams.t ? urlParams.t.trim() : 'd'
    let filterTrend = urlParams.filter_trend ? urlParams.filter_trend.trim() : 'b'
    let mgrId = urlParams.mgrid ? parseInt(urlParams.mgrid) : 0
    let bizMgrId = urlParams.bmid ? parseInt(urlParams.bmid) : 0
    let minT1Margin = urlParams.mint1 ? urlParams.mint1.trim() : ''
    let filterTrendDate = urlParams.date ? urlParams.date.trim() : ''

    useEffect(() => {
        getReport()
    }, [])

    const getReport = () => {
        const mbToken = localStorage.getItem('adm-auth-token')
        setDataLoaded(false)

        let url = type === 'affiliate' ? `${process.env.REACT_APP_API_URL}/reports/affiliates/trend?t=${timeFrame}&filter_trend=${filterTrend}&type=a` : `${process.env.REACT_APP_API_URL}/reports/offers/trend?t=${timeFrame}&filter_trend=${filterTrend}&type=o`

        if (mgrId > 0) {
            url += `&mgrid=${mgrId}`
        }

        if (type === 'campaign' && bizMgrId > 0) {
            url += `&bmid=${bizMgrId}`
        }

        if (type === 'campaign' && filterTrendDate !== '') {
            url += `&date=${filterTrendDate}`
        }

        if (isNaN(minT1Margin)) {
            showSystemMessage('error', 'Minimum T1 margin must be a number')
        }

        if (minT1Margin.trim() !== '') {
            url += `&mint1=${minT1Margin}`
        }

        fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true && result.report) {
                    setDataLoaded(true)
                    setLastUpdated(result.last_updated)
                    setReport(result.report)
                    setTotals(result.totals)
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                    }
                    return
                } else {
                    showSystemMessage('error', result.errors.message)
                    return
                }
            })
            .catch((error) => {
                console.log('Error in ReportCampaignTrends: ', error)
                showSystemMessage('error', 'Connection Error.')
                return
            })
    }

    const getReportOutput = () => {
        let timeRatio

        if (timeFrame === 'd') {
            let date1 = moment().startOf('day')
            let date2 = moment()
            let timeDiff = date2.diff(date1, 'seconds')
            timeRatio = 86400 / timeDiff
        } else if (timeFrame === 'w') {
            let date1 = moment().subtract((moment().day()), 'days').startOf('day')
            let date2 = moment()
            let timeDiff = date2.diff(date1, 'seconds')
            timeRatio = 604800 / timeDiff
        } else if (timeFrame === 'm') {
            let date1 = moment().startOf('month')
            let date2 = moment()
            let timeDiff = date2.diff(date1, 'seconds')
            timeRatio = 2628000 / timeDiff
        } else if (timeFrame === 'y') {
            let date1 = moment().startOf('year')
            let date2 = moment()
            let timeDiff = date2.diff(date1, 'seconds')
            timeRatio = 31540000 / timeDiff
        }

        return (
            <React.Fragment>
                <TableContainer
                    reportType={type === 'affiliate' ? 'affiliate trend' : 'campaign trend'}
                    timeRatio={timeRatio}
                    data={report}
                    totals={totals}
                    columns={[
                        {
                            Header: 'Rank',
                            accessor: 'rank',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'left' }}>{type === 'affiliate' ? 'Affiliate' : 'Campaign'}</div>,
                            accessor: 'name',
                            Cell: (tableData) => {
                                return type === 'affiliate' ? (
                                    <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status} priority={tableData.data[tableData.row.index].priority} />
                                ) : (
                                    <div className="d-flex">
                                        <Campaign oid={tableData.data[tableData.row.index].offer_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status} />
                                        <span className="text-secondary ml-2">({tableData.data[tableData.row.index].full_name})</span>
                                    </div>
                                )
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Projected Margin</div>,
                            accessor: 'D0',
                            Cell: ({ cell: { value } }) => {
                                return (
                                    <div className="text-right">
                                        <Dollars value={value * timeRatio} />
                                    </div>
                                )
                            },
                        },
                        {
                            Header: () => <div className='text-right'>Current Margin</div>,
                            accessor: 'item',
                            Cell: (tableData) => {
                                const value = tableData.data[tableData.row.index].D0
                                return (
                                    <div className='text-right'>
                                        <Dollars value={value} />
                                    </div>
                                )
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>T-1 Margin</div>,
                            accessor: 'D1',
                            Cell: ({ cell: { value } }) => {
                                return (
                                    <div style={{ textAlign: 'right' }}>
                                        <Dollars value={value} />
                                    </div>
                                )
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>T-2 Margin</div>,
                            accessor: 'D2',
                            Cell: ({ cell: { value } }) => {
                                return (
                                    <div style={{ textAlign: 'right' }}>
                                        <Dollars value={value} />
                                    </div>
                                )
                            },
                        },
                        {
                            Header: () => (
                                <div style={{ textAlign: 'right' }}>
                                    <Triangle size={10} /> Margin
                                </div>
                            ),
                            accessor: 'delta',
                            Cell: ({ cell: { value } }) => {
                                let classes = `text-right `
                                classes += value < 0 ? `text-danger` : `text-success`
                                return (
                                    <div className={classes}>
                                        <Dollars value={value} />
                                    </div>
                                )
                            },
                        },
                        {
                            Header: () => (
                                <div style={{ textAlign: 'right' }}>
                                    <Triangle size={10} /> Margin %
                                </div>
                            ),
                            accessor: 'deltap',
                            Cell: (tableData) => {
                                let classes = `text-right `
                                let deltaValue = tableData.data[tableData.row.index].deltap
                                let delta = <span></span>

                                if (tableData.data[tableData.row.index].D1 > tableData.data[tableData.row.index].D2) {
                                    delta = <span style={{ color: 'green', fontSize: '10px' }}>&#9650;</span>
                                    classes += `text-success`
                                    if (deltaValue < 0) { deltaValue = deltaValue * -1 }
                                } else if (tableData.data[tableData.row.index].D1 < tableData.data[tableData.row.index].D2) {
                                    delta = <span style={{ color: 'red', fontSize: '10px' }}>&#9660;</span>
                                    classes += `text-danger`
                                    if (deltaValue > 0) { deltaValue = deltaValue * -1 }
                                } else {
                                    delta = ''
                                }

                                return (
                                    <div className={classes}>
                                        {Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(deltaValue)} % {delta}
                                    </div>
                                )
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}></div>,
                            accessor: type === 'affiliate' ? 'affiliate_id' : 'offer_id',
                            Cell: ({ cell: { value } }) => {
                                return (
                                    <div style={{ textAlign: 'right' }}>
                                        <Link to={type === 'affiliate' ? `/reports/affiliateTrendDetail?aid=${value}` : `/reports/campaignTrendDetail?oid=${value}`} target="_blank">
                                            Trends
                                        </Link>
                                    </div>
                                )
                            },
                        },
                    ]}
                />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className='px-3 pb-3'>(Last Updated: {dataLoaded ? moment(lastUpdated).utc().format('YYYY-MM-DD HH:mm:ss') : spinner})</div>
            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for this parameters.</div> : getReportOutput() : <Loading></Loading>}
        </React.Fragment>
    )
}

export default ReportCampaignTrends
